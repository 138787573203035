export const knackReadableFields = {
  JOBS: {
    id: 'ID',
    field_1: 'JOB_NAME',
    field_16: 'JOB_NUMBER',
    field_2: 'JOB_STATUS',
    field_32: 'COMPANY',
    field_33: 'NUMBER_OF_LOCATIONS',
    field_34: 'NUMBER_OF_COMPLETED_LOCATIONS',
    field_35: 'NUMBER_OF_SCHEDULED_LOCATIONS',
    field_36: 'NUMBER_OF_LOCATIONS_READY_TO_WORK',
    field_37: 'NUMBER_OF_LOCATIONS_ON_HOLD',
    field_38: 'TOTAL_INVOICED_AMOUNT',
    field_41: 'TOTAL_LOCATION_LF',
    field_42: 'TOTAL_COMPLETED_LF',
    field_76: 'ESTIMATED_COSTS',
    field_77: 'ESTIMATED_BILLINGS',
    field_80: 'JOB_ID',
    field_120: 'PROJECT_MANAGER',
    field_132: 'DATE_BID_SUBMITTED',
    field_133: 'DATE_JOB_AWARDED',
    field_134: 'PROJECTED_START_OF_WORK',
    field_135: 'PROJECTED_COMPLETION_OF_WORK',
    field_137: 'JOB_SETUP_DUE_DATE',
    field_143: 'PROFIT_MARGIN',
    field_147: 'LOCATIONS_WAITING_FOR_NTP',
    field_148: 'NUMBER_OF_PERMITS_AWAITING_APPROVAL',
    field_149: 'NUMBER_OF_PERMITS_AWAITING_PAYMENT',
    field_150: 'NUMBER_OF_LOCATIONS_INVOICED',
    field_151: 'NUMBER_OF_LOCATIONS_PAID',
    field_153: 'NUMBER_OF_PERMITS_APPLIED_FOR_INCLUSIVE_OF_SUBSEQUENT_STATUSES',
    field_154: 'NUMBER_OF_PERMITS_PAID_FOR_INCLUSIVE_OF_SUBSEQUENT_STATUSES',
    field_155: 'NUMBER_OF_LOCATIONS_COMPLETED_INCLUSIVE_OF_SUBSEQUENT_STATUSES',
    field_156: 'NUMBER_OF_LOCATIONS_BILLED_INCLUSIVE_OF_SUBSEQUENT_STATUSES',
    field_296: 'CLIENT_NAME',
    field_489: 'NUMBER_OF_LOCATIONS_AWAITING_NTP',
    field_737: 'MOST_RECENT_DAILY_REPORT',
    field_883: 'NUMBER_OF_MATERIAL_PURCHASE_ORDERS',
    field_888: 'TOTAL_INVENTORY_PURCHASES',
    field_889: 'TOTAL_VALUE_REMAINING_INVENTORY',
    field_890: 'TOTAL_VALUE_INVENTORY_USED',
    field_894: 'TOTAL_ALL_MATERIAL_COSTS',
    field_936: 'SCHEDULED_BID_DATE',
    field_937: 'PREBID_MEETING_DATE',
    field_938: 'ESTIMATOR_NOTES_AND_COMMENTS',
    field_939: 'MP_DECISION',
    field_940: 'ESTIMATED_BID_VALUE',
    field_941: 'BID_ITEM_SHEET',
    field_942: 'PLANS_AND_SPECS',
    field_943: 'SHORT_SUMMARY_OF_WORK',
    field_944: 'BID_DOCUMENTS__UNIT_PRICES',
    field_945: 'HCSS_BID_',
    field_958: 'LOW_BIDDER',
    field_959: 'BID_PLACE_XX_OUT_OF_YY',
    field_960: 'LOW_BID_',
    field_961: 'WCI_BID_PRICE',
    field_962: 'TOTAL__OF_BIDDERS',
    field_963: 'WCI_PLACE',
    field_964: 'BID_RESULTS',
    field_986: 'CIVIL_BID_DOCUMENTS',
    field_1112: 'COST_CODES',
    field_1113: 'PHASES',
    field_1265: 'SUM_TOTAL__REPORTED',
    field_1266: 'TOTAL_APPROVED_VALUE',
    field_1267: 'SUM_TOTAL_PAID',
    field_1268: 'TOTAL_DISPUTED_',
    field_1269: 'TOTAL_HELD_',
    field_1341: 'CONTRACT_NUMBER',
    field_1368: 'ALLOW_REQUISITION_ITEMS',
    field_1401: 'REQUIRES_5G_FOUNDATION',
    field_1410: 'TOTAL_BILLABLE_VALUE',
  },
  LOCATIONS: {
    id: 'ID',
    field_19: 'IDENTIFIER',
    field_4: 'JOB',
    field_5: 'LOCATION',
    field_1010: 'DEFAULT_PHASE',
    field_332: 'PHASE_',
    field_7: 'PERMIT__WORK_HOURS',
    field_21: 'LF',
    field_22: 'CODE_53_MARKOUT_DATE',
    field_23: 'STATUS',
    field_24: 'COMPLETION_DATE',
    field_27: 'INVOICE_AMOUNT',
    field_28: 'INVOICE_DATE',
    field_29: 'PAID_DATE',
    field_30: 'STATUS_COMMENTS',
    field_46: 'PERMITTED_START_DATE',
    field_47: 'PERMITTED_END_DATE',
    field_49: 'PROTECTED',
    field_50: 'BUILDING_ACCESS',
    field_73: 'PAID_AMOUNT',
    field_75: 'PERMITS',
    field_78: 'LOCATION_ID',
    field_85: 'NOTICE_TO_PROCEED',
    field_92: 'DATE_PERMIT_APPLIED_FOR',
    field_93: 'DATE_PERMIT_PAID_FOR',
    field_94: 'WALK_THRU_DATE',
    field_95: 'INVOICE_',
    field_96: 'CHANGE_ORDER_1_INVOICE_',
    field_97: 'CHANGE_ORDER_2_INVOICE_',
    field_98: 'CHANGE_ORDER_3_INVOICE_',
    field_99: 'CHANGE_ORDER_1_INVOICE_AMOUNT',
    field_100: 'CHANGE_ORDER_2_INVOICE_AMOUNT',
    field_101: 'CHANGE_ORDER_3_INVOICE_AMOUNT',
    field_102: 'TOTAL_BILLING',
    field_104: 'PERMIT_APPLICATION_',
    field_1454: 'PERMIT_APPLICATION_NUMBER_LENGTH',
    field_108: 'LOCATION_TYPE',
    field_109: 'SPECIAL_STIPULATIONS',
    field_122: 'PROJECT_MANAGER',
    field_130: 'PERMIT_START_TIME',
    field_131: 'PERMIT_END_TIME',
    field_138: 'CREATED_DATE',
    field_141: 'SCHEDULED_WORK_DATE',
    field_157: 'CODE_53_TICKET_',
    field_160: 'DATE_PERMIT_APPROVED',
    field_192: 'CO_1_INVOICE_DATE',
    field_193: 'CO_2_INVOICE_DATE',
    field_194: 'CO_3_INVOICE_DATE',
    field_239: 'PO_',
    field_240: 'PO_REQUEST_DATE',
    field_241: 'PO_FILE',
    field_242: 'PO_AMOUNT',
    field_295: 'JOB_NUMBER',
    field_297: 'CLIENT_NAME',
    field_337: 'JOB_NUMBER__IDENTIFIER',
    field_361: 'JOB_TYPE',
    field_364: 'DEFAULT_COST_CODE',
    field_1175: 'DEFAULT_COST_CODE_NUMBER',
    field_463: 'CLIENT',
    field_465: 'PERMIT_',
    field_475: 'PROJECT_MANAGER_NOTES',
    field_476: 'TRAFFIC_STIPULATIONS_AND_CLOSURES',
    field_477: 'DATE_SURVEYED',
    field_478: 'SURVEY_NOTES',
    field_479: 'SURVEY_DOCS',
    field_480: 'SURVEYED_BY',
    field_481: 'REQUEST_SURVEY',
    field_671: 'SURVEY_PRIORITY',
    field_490: 'LOCATION_PLAN_DRAWING',
    field_492: 'OWNER_REPRESENTATIVE',
    field_494: 'FINAL_RESTORATION',
    field_495: 'MICRO_ID',
    field_521: 'ARE_ALL_DAILIES_COMPLETED_AND_CORRECT',
    field_522: 'HAS_FINAL_RESTORATION_BEEN_ORDERED',
    field_523: 'HAS_INVOICE_PACKAGE_BEEN_SUBMITTED',
    field_524: 'HAS_PROTECTED_STREET_BEEN_LOGGED_IN',
    field_602: 'EMERGENCY_LOCATION',
    field_603: 'NUMBER_OF_DAILY_REPORTS',
    field_613: 'NTP_DATE',
    field_614: 'PO_DATE',
    field_616: 'ON_HOLD',
    field_644: 'RFP_DATE',
    field_645: 'BID_BY',
    field_646: 'BID_DATE',
    field_647: 'BID_PACKAGE',
    field_648: 'RFP_EMAIL',
    field_649: 'DATE_SURVEY_REQUESTED',
    field_659: 'BARRELS_COMPLETED',
    field_662: 'VZ_SCHEDULED',
    field_660: 'SUBMITTED_PRICE_PROPOSAL',
    field_661: 'BID_IN_REVIEW',
    field_669: 'BID_AWARDED',
    field_670: 'AWARD_DATE',
    field_736: 'BID_STATUS',
    field_673: 'EARLIEST_DAILY_REPORT',
    field_674: 'MOST_RECENT_REPORT_DATE',
    field_678: 'JOB_CLEANUP_WORK_ORDER_SCHEDULED',
    field_687: 'DATE_THAT_MOST_RECENT_SETUP_WAS_CREATED',
    field_689: 'MOST_RECENT_SETUP_DATE_FORMULA',
    field_690: 'MOST_RECENT_REPORT_DATE_FORMULA',
    field_699: 'MOST_RECENT_REPORT_CREATED_DATE',
    field_700: 'MOST_RECENT_REPORT_CREATED_DATE_FORMULA',
    field_688: 'LOCATION_NEEDS_SETUP',
    field_739: 'VZ_INSPECTION_REQUIRED',
    field_754: 'COST_ESTIMATE',
    field_820: 'BID_NOTES',
    field_872: 'ASBUILT_DRAWING',
    field_873: 'INVOICE_PACKAGE',
    field_874: 'CHANGE_ORDER_INVOICE_PACKAGE',
    field_911: 'AFFILIATED_LOCATION',
    field_947: 'CONSTRUCTION_PHOTO_WALLET',
    field_948: 'PRECONSTRUCTION_VIDEO',
    field_949: 'POSTCONSTRUCTION_VIDEO',
    field_957: 'GOOGLE_DRIVE',
    field_980: 'GOOGLE_DRIVE_APP_MOBILE_LINK',
    field_967: 'PAVING_REQUIRED',
    field_973: 'CLEANUP_REQUESTED',
    field_984: 'DESCRIPTION_OF_CLEANUP_NEEDED',
    field_1342: 'JOB_CONTRACT_NUMBER',
    field_1369: 'JOB_ALLOWS_REQUISITION_ITEMS',
    field_1402: 'JOB_REQUIRES_5G_FOUNDATION',
    field_1414: 'PERMIT_STATUS',
    field_1430: 'VZ_INSPECTION_DATE',
    field_1433: 'INSTALL_5G_FOUNDATION_DATE',
    field_1442: 'LOCATION_PLAN_DRAWING_LARAVEL',
  },
  CREW: {
    id: 'ID',
    field_10: 'CREW_NAME',
    field_74: 'NUMBER_OF_DEPLOYMENTS_SCHEDULED_AFTER_TODAY',
    field_82: 'CREW_ID',
    field_145: 'CREW_STATUS',
    field_146: 'CREW_NOTES',
    field_611: 'FOREMAN',
  },
  TASKS: {
    id: 'ID',
    field_13: 'TASK_NAME',
    field_136: 'JOB',
    field_124: 'ASSIGNED_TO',
    field_127: 'TASK_DESCRIPTION',
    field_128: 'TASK_DUE_DATE',
    field_129: 'TASK_STATUS',
    field_1254: 'LINK',
    field_83: 'TASK_ID',
    field_1404: 'PROJECT',
  },
  COMPANY: {
    id: 'ID',
    field_17: 'COMPANY_NAME__AGENCY',
    field_79: 'COMPANY_ID',
    field_105: 'ACCOUNTING_DEPT_POINT_OF_CONTACT',
    field_106: 'ACCOUNTING_DEPT_EMAIL',
    field_107: 'ACCOUNTING_DEPT_PHONE_NUMBER',
    field_977: 'ESTIMATORS',
    field_1434: 'SNOWFLAKE',
  },
  ACCOUNTS: {
    id: 'ID',
    field_53: 'NAME',
    field_54: 'EMAIL',
    field_55: 'PASSWORD',
    field_56: 'USER_STATUS',
    field_57: 'USER_ROLES',
  },
  ESTIMATOR: {
    id: 'ID',
    field_58: 'NAME',
    field_59: 'EMAIL',
    field_60: 'PASSWORD',
    field_61: 'USER_STATUS',
    field_62: 'USER_ROLES',
  },
  NODE_SCHEDULER: {
    id: 'ID',
    field_63: 'NAME',
    field_64: 'EMAIL',
    field_65: 'PASSWORD',
    field_66: 'USER_STATUS',
    field_67: 'USER_ROLES',
  },
  MANAGERADMIN: {
    id: 'ID',
    field_68: 'NAME',
    field_69: 'EMAIL',
    field_70: 'PASSWORD',
    field_71: 'USER_STATUS',
    field_72: 'USER_ROLES',
  },
  PERMIT_MANAGEMENT: {
    id: 'ID',
    field_110: 'NAME',
    field_111: 'EMAIL',
    field_112: 'PASSWORD',
    field_113: 'USER_STATUS',
    field_114: 'USER_ROLES',
  },
  PROJECT_MANAGER: {
    id: 'ID',
    field_115: 'NAME',
    field_116: 'EMAIL',
    field_117: 'PASSWORD',
    field_118: 'USER_STATUS',
    field_119: 'USER_ROLES',
  },
  SUB_CONTRACTOR: {
    id: 'ID',
    field_163: 'NAME',
    field_164: 'EMAIL',
    field_165: 'PASSWORD',
    field_166: 'USER_STATUS',
    field_167: 'USER_ROLES',
    field_181: 'SUB_CONTRACTOR_COMPANY_NAME',
  },
  DAILY_REPORT: {
    id: 'ID',
    field_245: 'WEATHER',
    field_243: 'DAILY_REPORT_NAME',
    field_244: 'REPORT_DATE',
    field_246: 'SUBCONTRACTORSSUPPLIERS',
    field_247: 'MATERIAL_ORDERED',
    field_248: 'TODAYS_SCOPE_OF_WORK',
    field_249: 'FOREMANS_NOTES',
    field_1093: 'PAYROLL_NOTES',
    field_250: 'DESCRIPTION_OF_WORK_COMPLETED_TODAY',
    field_251: 'DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY',
    field_252: 'LOCATION',
    field_253: 'LOCATION_IDENTIFIER',
    field_254: 'LOCATION_ADDRESS',
    field_255: 'PERMIT_INFO',
    field_298: 'CLIENT_NAME',
    field_299: 'REPORT_STATUS',
    field_305: 'JOB_NUMBER',
    field_307: 'PERMIT_START_TIME',
    field_308: 'PERMIT_END_TIME',
    field_333: 'LOCATION_PHASE_',
    field_334: 'FOREMAN',
    field_335: 'FOREMANS_NAME',
    field_336: 'FOREMANS_EMAIL',
    field_353: 'DAILY_REPORT_NAME_FORMULA',
    field_356: 'JOB_TYPE',
    field_366: 'COST_CODE',
    field_369: 'CHANGE_OF_CONDITIONS',
    field_373: 'APP_LINK',
    field_374: 'APP_LINK_URL',
    field_393: 'CHANGE_OF_CONDITIONS_NOTES',
    field_394: 'INCIDENT_ON_SITE',
    field_395: 'INCIDENT_REPORT_COMPLETED',
    field_483: 'PERMIT_INFO_FOR_REPORT_APP',
    field_484: 'REVISIONS',
    field_606: 'NUMBER_OF_MATERIAL_USAGES',
    field_607: 'NUMBER_OF_COMPLETED_WORK_ITEMS',
    field_618: 'LOCATION_PERMIT_WORK_HOURS',
    field_642: 'PROJECT_MANAGER_NOTES_FROM_LOCATION',
    field_668: 'WORK_ORDERS',
    field_672: 'REPORT_DATE_FORMULA',
    field_697: 'CREATED_DATE',
    field_698: 'REPORT_CREATED_DATE_FORMULA',
    field_974: 'GOOGLE_DRIVE_FROM_LOCATION',
    field_978: 'JOB_PHOTOS_UPLOADED',
    field_979: 'ASBUILT_UPLOADED',
    field_981: 'JOB_PHOTOS_EMAIL_SENT',
    field_982: 'ASBUILT_EMAIL_SENT',
    field_1343: 'JOB_CONTRACT_NUMBER',
    field_1396: 'JOB_ALLOWS_REQUISITION_ITEMS',
    field_1413: 'RENTAL_DUMP_TRUCK_NOTES',
    field_1431: 'NUMBER_OF_LABOR_ASSIGNMENTS',
    field_1432: 'NUMBER_OF_EQUIPMENT_USAGES',
  },
  LABORER: {
    id: 'ID',
    field_256: 'LABORER_NAME',
    field_257: 'EMPLOYEE_NUMBER',
    field_258: 'LABORER_TYPE',
    field_300: 'STATUS',
    field_304: 'EMAIL',
    field_342: 'ON_PAYROLL',
    field_370: 'HOURLY_LABOR_RATE_REG',
    field_371: 'HOURLY_LABOR_RATE_OT',
    field_372: 'HOURLY_LABOR_RATE_DT',
    field_405: 'UNION',
    field_612: 'DEFAULT_CREW',
    field_617: 'LABORER_RATING',
    field_641: 'CREW_NUMBER',
    field_643: 'EXCLUDE_FROM_DISPATCHER',
    field_813: 'DEFAULT_FOREMAN',
    field_819: 'SMS_NOTIFICATION_PHONE_NUMBER',
    field_902: 'LABOR_RATE',
    field_1015: 'FIRST_NAME',
    field_1016: 'LAST_NAME',
    field_1017: 'RECORD_ID',
    field_1024: 'LABORER_NAME_FORMULA',
    field_1026: 'NOTES',
    field_1400: 'EXCLUDE_FROM_SCHEDULER',
    field_1403: 'SCHEDULER_ORDER',
  },
  EQUIPMENT: {
    id: 'ID',
    field_259: 'EQUIPMENT_NAME',
    field_260: 'ASSET_NUMBER',
    field_301: 'STATUS',
    field_357: 'INCLUDE_IN_JOB_TYPE_DEFAULT',
    field_615: 'DEFAULT_NOTES',
    field_752: 'EQUIPMENT_TYPE',
    field_753: 'DAILY_RATE',
    field_836: 'OUT_OF_SERVICE',
    field_909: 'EQUIPMENT_TYPE_NAME',
    field_976: 'DEFAULT_FOREMAN',
    field_1028: 'STANDBY_RATE',
    field_1412: 'EXCLUDE_FROM_BILLING',
  },
  MATERIALPART: {
    id: 'ID',
    field_261: 'MATERIALPART_NAME',
    field_1419: 'HAS_INVENTORY',
    field_262: 'PART_ID',
    field_263: 'UNIT_TYPE',
    field_302: 'STATUS',
    field_358: 'INCLUDE_IN_JOB_TYPE_DEFAULT',
    field_469: 'INCLUDE_IN_JOB_TYPE_PICKLIST',
    field_663: 'UNIT_COST',
  },
  PAYWORK_ITEM: {
    id: 'ID',
    field_264: 'PAYWORK_ITEM_NAME',
    field_289: 'UNIT_TYPE',
    field_291: 'UNIT_PRICE',
    field_303: 'STATUS',
    field_1437: 'MATCHING_COST_CODE',
    field_323: 'ITEM_',
    field_359: 'INCLUDE_IN_JOB_TYPE_DEFAULT',
    field_402: 'RECORD_ID',
    field_470: 'INCLUDE_IN_JOB_TYPE_PICKLIST',
    field_863: 'DESCRIPTION',
    field_965: 'PAYMENT_REQUISITION',
    field_989: '_BID_UNIT_PRICE_',
    field_1438: 'MATCHING_COST_CODE_NUMBER_FORMULA',
  },
  LABOR_ASSIGNMENT: {
    id: 'ID',
    field_265: 'LABOR_ASSIGNMENT_NAME',
    field_266: 'LABORER',
    field_267: 'START_TIME',
    field_268: 'END_TIME',
    field_269: 'LOCATION_START_',
    field_271: 'NO_LUNCH',
    field_272: 'TOTAL_HOURS',
    field_275: 'DAILY_REPORT',
    field_306: 'LABORERTYPE',
    field_309: 'LABORER_STATUS',
    field_310: 'LABORER_EMPLOYEE_NUMBER',
    field_311: 'LABORER_NAME',
    field_345: 'TABLE_ORDER_NUMBER',
    field_349: 'LABOR_ASSIGNMENT_ID',
    field_387: 'REPORT_DATE',
    field_360: 'PAYROLL_DATE',
    field_378: 'LOCATION',
    field_362: 'JOB_NUMBER',
    field_363: 'DEFAULT_PHASE',
    field_365: 'ON_PAYROLL',
    field_367: 'COST_CODE',
    field_368: 'REPORT_STATUS',
    field_386: 'DAY_OF_WEEK',
    field_273: 'REGULAR_HOURS',
    field_407: 'UNION',
    field_408: 'UNION_TEXT',
    field_274: 'OT',
    field_403: 'TIME__12',
    field_404: 'DOUBLE_TIME',
    field_409: 'DATE',
    field_471: 'NUMBER_OF_HOURS_FOR_TIME__MATERIALS_BILLING',
    field_842: 'ASSIGNMENT_SMS_STATUS',
    field_1408: 'LABORER_PHONE_NUMBER_SANITIZED',
    field_843: 'LOCATION_ADDRESS',
    field_844: 'LOCATION_IDENTIFIER',
    field_880: 'CLASSIFICATION',
    field_901: 'LABOR_RATE',
    field_903: 'HOURLY_RATE_REGULAR',
    field_906: 'HOURLY_RATE_OT',
    field_907: 'HOURLY_RATE_DT',
    field_908: 'TOTAL_LABOR_COST',
    field_1002: 'COST_CODE_1',
    field_995: 'COST_CODE_1_',
    field_1003: 'COST_CODE_2',
    field_997: 'COST_CODE_2_',
    field_1007: 'PHASE_1',
    field_994: 'PHASE_1_',
    field_1008: 'PHASE_2',
    field_996: 'PHASE_2_',
    field_1018: 'NAME_LAST_FIRST',
    field_1025: 'NOTES',
    field_1038: 'COST_CODE_NUMBER_1',
    field_1039: 'COST_CODE_NUMBER_2',
    field_1040: 'PHASE_NUMBER_1',
    field_1041: 'PHASE_NUMBER_2',
    field_1272: 'TOTAL_SHIFT_ITEMS',
  },
  EQUIPMENT_USAGE: {
    id: 'ID',
    field_276: 'EQUIPMENT_USAGE_NAME',
    field_277: 'DAILY_REPORT',
    field_278: 'EQUIPMENT',
    field_279: 'LABORER',
    field_312: 'EQUIPMENT_NAME',
    field_313: 'EQUIPMENT_ASSET_',
    field_314: 'EQUIPMENT_STATUS',
    field_315: 'LABORER_STATUS',
    field_316: 'LABORER_NAME',
    field_317: 'LABORER_TYPE',
    field_318: 'LABORER_EMPLOYEE_NUMBER',
    field_329: 'EQUIPMENT_ON_SITE_TODAY',
    field_331: 'NULL_VALUE_RECORD',
    field_340: 'EQUIPMENT_NOTES',
    field_346: 'TABLE_ORDER_NUMBER',
    field_350: 'EQUIPMENT_USAGE_ID',
    field_380: 'LOCATION',
    field_383: 'REPORT_DATE',
    field_392: 'DAILY_RATE',
    field_396: 'PHASE_',
    field_397: 'COST_CODE',
    field_400: 'JOB_NUMBER',
    field_472: 'INCLUDE_IN_TIME__MATERIALS_BILLING',
    field_485: 'COST_CLASS',
    field_486: 'COST_UNIT',
    field_487: 'TYPE',
    field_488: 'JOB_IDENTIFIER',
    field_588: 'TM_HOURS',
    field_910: 'EQUIPMENT_TYPE',
    field_988: 'LOCATION_IDENTIFIER',
    field_1014: 'STANDBY',
    field_1029: 'STANDBY_BOOLEAN',
    field_1273: 'TOTAL_SHIFT_ITEMS',
  },
  MATERIAL_USAGE: {
    id: 'ID',
    field_280: 'MATERIAL_USAGE_NAME',
    field_281: 'DAILY_REPORT',
    field_282: 'MATERIALPART',
    field_283: 'QUANTITY_USED',
    field_284: 'QUANTITY_SENT_OUT',
    field_293: 'UNIT_TYPE',
    field_319: 'MATERIAL_NAME',
    field_320: 'MATERIAL_PART_ID',
    field_321: 'MATERIAL_STATUS',
    field_322: 'MATERIAL_UNIT_TYPE',
    field_344: 'MATERIAL_NOTES',
    field_347: 'TABLE_ORDER_NUMBER',
    field_351: 'MATERIAL_USAGE_ID',
    field_381: 'LOCATION',
    field_384: 'REPORT_DATE',
    field_398: 'PHASE_',
    field_399: 'COST_CODE',
    field_401: 'JOB_NUMBER',
    field_473: 'INCLUDE_IN_TIME__MATERIALS_BILLING',
    field_773: 'JOB',
    field_772: 'JOB_INVENTORY_ITEM',
    field_786: 'COST_OF_MATERIAL_USED',
    field_1416: 'IS_SPECIAL_MATERIAL',
  },
  COMPLETED_WORK_QUANTITY: {
    id: 'ID',
    field_285: 'COMPLETED_WORK_QUANTITY_NAME',
    field_286: 'DAILY_REPORT',
    field_287: 'PAYWORK_ITEM',
    field_288: 'QUANTITY_COMPLETED',
    field_292: 'UNIT_TYPE',
    field_324: 'PAYWORK_ITEM_NAME',
    field_1439: 'MATCHED_COST_CODE_NUMBER',
    field_1440: 'JOB_COST_CODE_FINGERPRINT_FORMULA',
    field_325: 'UNIT_PRICE',
    field_326: 'PAYWORK_ITEM_STATUS',
    field_1092: 'DISPUTED_',
    field_327: 'PAYWORK_ITEM_',
    field_341: 'COMPLETION_NOTES',
    field_348: 'TABLE_ORDER_NUMBER',
    field_352: 'COMPLETED_WORK_ID',
    field_375: 'JOB_NUMBER',
    field_376: 'PHASE',
    field_377: 'REPORT_DATE',
    field_382: 'LOCATION',
    field_797: 'JOB_PAY_ITEM',
    field_800: 'TOTAL_PAID',
    field_966: 'PAYMENT_REQUISITION',
    field_1059: 'NYS_DOT_APPROVED_QUANTITY',
    field_1087: 'PAID_QUANTITY',
    field_1060: 'STATUS',
    field_1061: 'TOTAL__REPORTED',
    field_1062: 'DIFF_IN_QTY',
    field_1063: 'HELD_',
    field_1088: 'APPROVED_VALUE',
    field_1091: 'OVERRUN',
    field_1314: 'COMPLETED_BY',
  },
  CORRECTIVE_ACTION_REQUESTS: {
    id: 'ID',
    field_412: 'CORRECTIVE_ACTION_REQUESTS_NAME',
    field_413: 'DATE_RECIEVED',
    field_414: 'CAR_',
    field_415: 'FINE_AMOUNT',
    field_416: 'DESCRIPTION_OF_ACTION',
    field_417: 'LOCATION_IDENTIFIER',
    field_418: 'CAR_STATUS',
    field_419: 'FINE_PAID',
    field_420: 'DATE_FINE_PAID',
    field_421: 'AMOUNT_OF_FINE_PAID',
    field_422: 'FINE_CHECK_',
    field_423: 'DATE__WORK_COMPLETED',
    field_424: 'WORK_COMPLETED_NOTES',
    field_425: 'DATE_WORK_SCHEDULED',
    field_426: 'CAR_NAME_FORMULA',
    field_427: 'JOB',
    field_428: 'COMPANY',
    field_429: 'SCHEDULE_NOTES',
    field_430: 'WORK_TO_BE_DONE',
    field_431: 'CAR_COPY',
    field_432: 'DATE_SURVEYED',
    field_433: 'SURVEY_NOTES',
    field_434: 'WCIS_PERMIT',
    field_459: 'PERMIT_',
    field_460: 'CAR_RESOLUTION_DOCUMENT_1',
    field_461: 'CAR_RESOLUTION_DOCUMENT_2',
    field_466: 'MOST_RECENT_CAR_INSPECTION_DATE',
    field_467: 'CAR_DEADLINE_TO_RESOLVE',
    field_497: 'TYPE_OF_VIOLATION',
    field_498: 'INTERNAL_WORK_ORDER',
    field_510: 'TOTAL_FINES',
    field_512: 'WCIS_WORK',
    field_513: 'IS_VIOLATIONREPORT_ACCURATE',
    field_514: 'IS_ISSUE_RESOLVED',
    field_515: 'DATE_WORK_ORDER_CREATED',
    field_578: 'LOCATION',
    field_591: 'NUM_OF_INSPECTIONS',
    field_592: 'CAR_DATABASE_ID',
    field_593: 'SHORT_TEXT',
    field_597: 'TOTAL_FINES_PAID',
    field_598: 'TOTAL_OUTSTANDING_FINES',
    field_808: 'OUTSIDE_1YEAR_GUARANTEE',
    field_1052: 'BILLED_TO_CLIENT',
    field_1411: 'INVOICED',
  },
  SUMMONS: {
    id: 'ID',
    field_435: 'SUMMONS_NAME',
    field_436: 'LOCATION_IDENTIFIER_FOR_JOB',
    field_437: 'CORRECTIVE_ACTION_REQUEST',
    field_438: 'SUMMONS_',
    field_439: 'DATETIME_OF_SUMMONS',
    field_440: 'HEARING_DATETIME',
    field_441: 'HEARING_LOCATION',
    field_442: 'DETAILS_OF_VIOLATION',
    field_443: 'PERMIT_',
    field_444: 'MAX_PENALTY',
    field_445: 'CAR_',
    field_446: 'CLIENT_NAME',
    field_447: 'AMOUNT_OF_FINE_PAID',
    field_448: 'CHECK__FOR_FINE',
    field_449: 'DATE_FINE_PAID',
    field_450: 'WCI_RESPONSE_DATE',
    field_451: 'WCI_RESPONSE_DOCUMENT',
    field_452: 'COURT_APPEARANCE',
    field_453: 'SUMMARY_OF_APPEARANCE',
    field_454: 'DECISION_BY_COURT',
    field_455: 'DATE_OF_DECISION',
    field_456: 'STATUS',
    field_457: 'WCI_RESPONSE_NOTES',
    field_458: 'MIN_PENALTY',
    field_462: 'CLIENT',
    field_464: 'SUMMONS_COPY',
    field_468: 'FINE_RECEIPTDOCS',
    field_482: 'STREET_AND_LOCATION_OF_SUMMONS',
    field_499: 'TYPE_OF_VIOLATION',
    field_500: 'BACK_CHARGE_SUB',
    field_542: 'JOB_NUMBER',
    field_543: 'WCIS_PERMIT',
    field_544: 'OK_TO_PAY',
    field_587: 'COURT_RULING_DOC',
    field_821: 'JOB_',
    field_822: '_OF_MISSED_HEARINGS',
    field_845: 'OUTSIDE_OF_1YEAR_WARRANTY',
    field_846: '_MAX_PENALTY_',
    field_847: '_SUMMARY_OF_APPEARANCE_',
    field_848: 'AMOUNT_PAID',
    field_1053: 'BILLED_TO_CLIENT',
  },
  CAR_INSPECTIONS: {
    id: 'ID',
    field_502: 'CAR_INSPECTIONS_NAME',
    field_503: 'CORRECTIVE_ACTION_REQUEST',
    field_504: 'INSPECTION_NUMBER',
    field_505: 'INSPECTION__DATE',
    field_506: 'INSPECTION_REPORT',
    field_507: 'FINE_AMOUNT',
    field_508: 'DATE_FINE_PAID',
    field_509: 'FINE_CHECK_',
    field_594: 'CAR_DATABASE_ID',
    field_595: 'AMOUNT_OF_FINE_PAID',
    field_596: 'INSPECTION_DATABASE_ID',
    field_599: 'WCIS_WORK',
    field_600: 'WCIS_PERMIT',
    field_601: 'CAR_',
    field_651: 'COMPANY',
    field_653: 'CLIENT',
    field_654: 'JOB',
    field_655: 'LOCATION',
  },
  WORK_ORDERS: {
    id: 'ID',
    field_562: 'WORK_ORDERS_NAME',
    field_563: 'DATE_WORK_ORDER_CREATED',
    field_564: 'DATE_WORK_ORDER_COMPLETED',
    field_565: 'PICTURE_1',
    field_566: 'PICTURE_2',
    field_567: 'PICTURE_3',
    field_568: 'PICTURE_4',
    field_569: 'PICTURE_5',
    field_570: 'ASSIGNED_TO',
    field_571: 'WORK_TO_BE_DONE',
    field_572: 'CORRECTIVE_ACTION_REQUEST',
    field_573: 'SUMMONS',
    field_574: 'CAR_COPY',
    field_575: 'STATUS',
    field_576: 'CAR_',
    field_664: 'LOCATION_IDENTIFIER',
    field_579: 'WCIS_WORK',
    field_580: 'IS_VIOLATIONREPORT_ACCURATE',
    field_581: 'IS_WORK_COMPLETE',
    field_582: 'WORK_NOTES',
    field_584: 'ASSIGNED_BY',
    field_585: 'WORK_ORDER_INCREMENT',
    field_586: 'WORK_ORDER_',
    field_609: 'COPY_OF_VIOLATION',
    field_610: 'SUMMONS_',
    field_665: 'PRIORITY',
    field_667: 'WORK_ORDER_TYPE',
    field_679: 'SETUP_DIAGRAM',
    field_680: 'ASSIGNED_TO',
    field_681: 'PICTURE_6',
    field_682: 'PICTURE_7',
    field_683: 'PICTURE_8',
    field_684: 'PICTURE_9',
    field_685: 'PICTURE_10',
    field_686: 'WHAT_DATE_DOES_THIS_SETUP_COVER_UNTIL',
    field_877: 'SET_UP_CREW_PRECONSTRUCTION_VIDEO',
    field_878: 'CLEANUP_CREW_POSTCONSTRUCTION_VIDEO',
    field_983: 'ASSOCIATED_CARVIOLATION_',
  },
  INSURANCE_INCIDENTS: {
    id: 'ID',
    field_619: 'INSURANCE_INCIDENTS_NAME',
    field_620: 'CLAIM_TYPE',
    field_621: 'DATE_OF_INCIDENTS',
    field_622: 'STATUS',
    field_624: 'DESCRIPTION_OF_INCIDENT',
    field_625: 'FIELD_INCIDENT_REPORT',
    field_626: 'NOTES_AND_UPDATED_INFORMATION',
    field_628: 'CLAIMANT',
    field_629: 'CONTACT_NAME',
    field_630: 'CONTACT_PHONE_NUMBER',
    field_631: 'JOB_NUMBER1',
    field_632: 'LOCATIONADDRESS',
    field_744: 'LOCATION',
    field_633: 'PHOTOS_UPLOAD_PDF_FILE_WITH_ALL_PHOTOS',
    field_634: 'INSURANCE_SUBMITAL_DATE_',
    field_636: 'MOST_RECENT_CORRESPONDENCE_NOTES',
    field_637: 'INSURANCE_REPRESENTATIVE',
    field_638: 'INSURANCE_COMPANY',
    field_639: 'INITIAL_INSURANCE_NOTIFICATION_DATE',
    field_692: 'PERMITS_FROM_SITE',
    field_693: 'AS_BUILT_FROM_JOB',
    field_694: 'INVOICE_FOR_JOB',
    field_696: 'SITE_VIDEO',
    field_709: 'MOST_RECENT_CORRESPONDENCE_DATE',
    field_743: 'CLAIM_NUMBER',
    field_745: 'COMPLETELY_RESOLVED',
    field_746: 'DATE_COMPLETELY_RESOLVED',
    field_747: 'DESCRIPTIONNOTES_OF_RESOLUTION',
    field_748: 'JOB_NUMBER',
    field_912: 'DAILY_REPORTS',
    field_987: 'GOOGLE_DRIVE_FOR_INCIDENT_INFO',
  },
  INSURANCE_CORRESPONDENCE: {
    id: 'ID',
    field_701: 'INCIDENT_CORRESPONDENCE_NAME',
    field_702: 'INSURANCE_INCIDENT',
    field_703: 'DATE_OF_CORRESPONDENCE',
    field_706: 'SUBJECT_OF_CORRESPONDENCE',
    field_705: 'DESCRIPTIONNOTES_OF_CORRESPONDENCE',
    field_704: 'COPY_OF_CORRESPONDENCE',
    field_707: 'DATE_OF_CORRESPONDENCE_FORMULA',
  },
  PAVING_SUB_WORK: {
    id: 'ID',
    field_710: 'PAVING_SUB_WORK_NAME',
    field_711: 'LOCATION',
    field_712: 'REQUESTED_WORK',
    field_713: 'ADDITIONAL_INSTRUCTIONS',
    field_714: 'UPLOAD_DRAWING',
    field_715: 'PARKING_STIPULATIONS',
    field_716: 'SPECIALTY_LINE_STRIPING',
    field_717: 'INSTALL_TAGS_DETAILS',
    field_718: 'PERMIT_EXPIRES',
    field_719: 'ESTIMATED_SF',
    field_720: 'DATE_SENT_TO_PAVING_SUB',
    field_721: 'STATUS_OF_PO',
    field_722: 'PROTECTED',
    field_723: 'SUB_EMAIL',
    field_724: 'DATE_WORK_COMPLETED',
    field_725: 'NOTES_FROM_SUB_ABOUT_COMPLETED_WORK',
    field_726: 'SUB_EMAIL_HELPER_FIELD',
    field_727: 'PO_INCREMENT',
    field_728: 'PO_',
    field_729: 'PERMIT',
    field_730: 'PICTURE_1',
    field_731: 'PICTURE_2',
    field_732: 'PICTURE_3',
    field_733: 'PICTURE_4',
    field_734: 'PICTURE_5',
    field_735: 'INVOICE_',
    field_738: 'ADDRESS',
    field_760: 'TOTAL_ESTIMATED_SF',
    field_805: 'ACTUAL_SF_PAVED',
    field_806: 'ACTUAL_VS_EST',
    field_807: 'COST_FOR_LOCATION',
    field_809: 'EST_COST',
    field_1054: 'DO_NOT_PAY',
  },
  EQUIPMENT_TYPE: {
    id: 'ID',
    field_750: 'EQUIPMENT_TYPE_NAME',
    field_751: 'DAILY_RATE',
    field_1027: 'STANDBY_RATE',
  },
  PAVING_AREA: {
    id: 'ID',
    field_755: 'PAVING_AREA_NAME',
    field_756: 'PAVING_SUB_WORK',
    field_757: 'LENGTH',
    field_758: 'WIDTH',
    field_759: 'SF',
  },
  JOB_INVENTORY_ITEM: {
    id: 'ID',
    field_761: 'JOB_INVENTORY_ITEM_NAME',
    field_762: 'JOB',
    field_763: 'MATERIALPART',
    field_769: 'TOTAL_PURCHASED',
    field_770: 'TOTAL_SPENT',
    field_771: 'AVERAGE_COST_OF_INVENTORY',
    field_774: 'QUANTITY_USED',
    field_775: 'COST_OF_QUANTITY_USED',
    field_776: 'QUANTITY_OF_REMAINING_INVENTORY',
    field_777: 'VALUE_OF_REMAINING_INVENTORY',
    field_790: 'UNIT_TYPE',
    field_1420: 'MATERIAL_HAS_INVENTORY',
  },
  JOB_PURCHASE_ORDER_MATERIAL_ITEM: {
    id: 'ID',
    field_764: 'JOB_MATERIAL_PURCHASE_ORDER_NAME',
    field_765: 'JOB_INVENTORY_ITEM',
    field_766: 'QUANTITY_PURCHASED',
    field_767: 'COST_PER_UNIT',
    field_768: 'TOTAL_COST',
    field_779: 'JOB_MATERIAL_PURCHASE_ORDER',
    field_791: 'UNIT_TYPE',
    field_875: 'JOB_MATERIAL_PURCHASE_ORDER_ITEM_INCREMENT',
    field_876: 'JOB_MATERIAL_PURCHASE_ORDER_ITEM_',
    field_1064: 'PHASE',
    field_1065: 'COST_CODE',
    field_1077: 'QUOTE',
    field_1085: 'CERTIFICATION_STATUS',
  },
  JOB_PURCHASE_ORDER: {
    id: 'ID',
    field_778: 'JOB_MATERIAL_PURCHASE_ORDER_NAME',
    field_781: 'JOB',
    field_780: 'TOTAL_COST_OF_MATERIAL',
    field_927: 'TOTAL_COST_OF_EQUIPMENT_RENTAL',
    field_928: 'TOTAL_SUB_COST',
    field_782: 'DATE_PO_ISSUED',
    field_783: 'STATUS_OF_PO',
    field_785: 'VENDOR',
    field_871: 'PO_NUMBER_INCREMENT',
    field_884: 'PO_NUMBER_INCREMENT_FROM_JOB',
    field_882: 'PO_NUMBER_INCREMENT_ADD_DIGITS',
    field_881: 'PO_NUMBER',
    field_929: 'TOTAL_PO_COST',
    field_930: 'VENDOR_NAME',
    field_933: 'JOB_NUMBER',
    field_1055: 'INVOICE_',
    field_1075: 'INVOICED_AMOUNT',
    field_1076: 'NOTES',
    field_1084: 'CERTIFICATION_STATUS',
    field_1258: 'TAX_PERCENTAGE',
    field_1259: 'SUBTOTAL',
    field_1260: 'TOTAL_TAXES',
    field_1261: 'INVOICE_STATUS',
    field_1262: 'INVOICE_FILE',
    field_1418: 'EXPENSE_TYPE',
    field_1421: 'TAX_EXEMPTION_CERTIFICATION',
    field_1435: 'LEFT_TO_BE_INVOICED',
  },
  VENDOR: {
    id: 'ID',
    field_784: 'VENDOR_NAME',
    field_1071: 'VENDOR_NO',
    field_1072: 'STREET_ADDRESS',
    field_1073: 'CITYSTATEZIP',
    field_1068: 'CONTACT_NAME',
    field_1069: 'CONTACT_PHONE_',
    field_1070: 'CONTACT_EMAIL',
    field_1074: 'MWDBE_STATUS',
  },
  JOB_PAY_ITEM: {
    id: 'ID',
    field_792: 'JOB_PAY_ITEM_NAME',
    field_793: 'JOB',
    field_794: 'PAYWORK_ITEM',
    field_795: 'CONTRACT_QUANTITY',
    field_796: 'CONTRACT_AMOUNT',
    field_799: 'CONTRACT_UNIT_PRICE',
    field_798: 'TOTAL_COMPLETED_TO_DATE',
    field_801: 'TOTAL_EARNED_TO_DATE',
    field_802: 'TOTAL_REMAINING_QUANTITY_COVERED_UNDER_CONTRACT',
    field_803: 'TOTAL_REMAINING_AMOUNT_COVERED_UNDER_CONTRACT',
    field_804: 'RECORD_ID',
  },
  EQUIPMENT_SERVICE_TICKET: {
    id: 'ID',
    field_828: 'EQUIPMENT_SERVICE_TICKET_NAME',
    field_829: 'EQUIPMENT',
    field_830: 'DATE_TICKET_OPENED',
    field_831: 'DATE_TICKET_RESOLVED',
    field_832: 'DESCRIPTION_OF_SERVICE_NEEDED',
    field_833: 'REQUESTED_BY',
    field_834: 'REPAIRED_BY',
    field_835: 'IS_THE_PIECE_OUT_OF_SERVICE_AND_CANNOT_BE_USED',
    field_837: 'STATUS',
    field_838: 'DATE_SERVICE_STARTED',
    field_839: 'SERVICE_PLAN',
    field_840: 'NOTES_ABOUT_RESOLUTION',
    field_841: 'WAS_PROBLEM_FIXED',
    field_851: 'PERSON_USING_EQUIPMENT_AT_TIME_OF_INCIDENT',
    field_852: 'PHOTO_1_OF_PROBLEM',
    field_853: 'PHOTO_2_OF_PROBLEM',
    field_854: 'PHOTO_3_OF_PROBLEM',
    field_855: 'TICKET_',
    field_856: 'TICKET_NUMBER',
    field_857: 'MILEAGEHOURS',
    field_858: 'FILE_1',
    field_859: 'FILE_2',
    field_860: 'PHOTO_1_OF_REPAIR',
    field_861: 'PHOTO_2_OF_REPAIR',
    field_862: 'PHOTO_3_OF_REPAIR',
  },
  LABOR_RATE: {
    id: 'ID',
    field_895: 'LABOR_RATE_NAME',
    field_896: 'EFFECTIVE_DATE_START',
    field_897: 'EFFECTIVE_DATE_END',
    field_898: 'TYPE_OF_LABORER',
    field_900: 'STATUS',
    field_899: 'HOURLY_RATE_REGULAR',
    field_904: 'HOURLY_RATE_OT',
    field_905: 'HOURLY_RATE_DT',
  },
  JOB_PURCHASE_ORDER_EQUIPMENT_RENTAL_ITEM: {
    id: 'ID',
    field_913: 'DESCRIPTION',
    field_915: 'JOB_PURCHASE_ORDER',
    field_919: 'TOTAL_ESTIMATED_COST',
    field_925: 'SUPPORTING_DOCS',
    field_931: 'VENDOR_NAME',
    field_934: 'JOB_NUMBER',
    field_1056: 'RENTAL_LENGTH',
    field_1057: 'MULTIPLE_CHOICE',
    field_1058: 'RENTAL_RATE',
    field_1078: 'PHASE',
    field_1079: 'COST_CODE',
  },
  JOB_PURCHASE_ORDER_SUB_CONTRACTOR_ITEM: {
    id: 'ID',
    field_914: 'DESCRIPTION',
    field_916: 'JOB_PURCHASE_ORDER',
    field_922: 'LUMP_SUM',
    field_1257: 'QUANTITY',
    field_924: 'SUPPORTING_DOCS',
    field_932: 'VENDOR_NAME',
    field_935: 'JOB_NUMBER',
    field_1080: 'COST_CODE',
    field_1081: 'PHASE',
    field_1086: 'SUB_APPROVAL_STATUS',
  },
  BID_DOCUMENTS: {
    id: 'ID',
    field_950: 'BID_DOCUMENTS_NAME',
    field_951: 'JOB',
    field_952: 'DATE_DOCUMENT_ENTERED',
    field_953: 'DESCRIPTION_OF_DOCUMENT',
    field_954: 'NOTES_AND_INFORMATION',
    field_955: 'FILE',
    field_956: 'THIRD_PARTY_SHARING',
    field_985: 'BID_DOCUMENT_GOOGLE_DRIVE',
  },
  SURVEY_TEAM: {
    id: 'ID',
    field_968: 'NAME',
    field_969: 'EMAIL',
    field_970: 'PASSWORD',
    field_971: 'USER_STATUS',
    field_972: 'USER_ROLES',
  },
  COST_CODES: {
    id: 'ID',
    field_1032: 'COST_CODE_NUMBER',
    field_1393: 'SHIFT_ITEM_USAGES',
    field_1001: 'DESCRIPTION',
    field_1107: 'UNIT_OF_MEASUREMENT',
    field_1391: 'FOUNDATION_ID',
    field_1387: 'STATUS',
    field_1388: 'HAS_SPECIAL_SPEC',
    field_1389: 'SPEC_FILENAME',
    field_1390: 'SPEC_URL',
    field_1451: 'UNIT_OF_MEASURE',
  },
  PHASES: {
    id: 'ID',
    field_1033: 'PHASE_NUMBER',
    field_1006: 'DESCRIPTION',
  },
  CATCH_BASINS: {
    id: 'ID',
    field_1042: 'CATCH_BASIN_',
    field_1043: 'HIGHWAYROADWAY',
    field_1044: 'TRAVEL_DIRECTION',
    field_1045: 'DATE_WORK_COMPLETED',
    field_1046: 'DEPTH_OF_STRUCTURE',
    field_1047: 'DEBRIS_LEVEL',
    field_1048: 'NOTES',
    field_1049: 'TIME_TO_CLEAN',
    field_1050: 'BEFORE_PHOTO',
    field_1051: 'AFTER_PHOTO',
  },
  SHIFT_ITEMS: {
    id: 'ID',
    field_1095: 'REGULAR_HOURS',
    field_1096: 'OT_HOURS',
    field_1193: 'DT_HOURS',
    field_1097: 'PHASE',
    field_1098: 'COST_CODE',
    field_1394: 'COST_CODE_FOUNDATION_ID',
    field_1100: 'LABOR_ASSIGNMENT',
    field_1101: 'EQUIPMENT_USAGE',
    field_1116: 'TIME_CREATED',
    field_1178: 'REGULAR_HOURS_FROM_ASSET',
    field_1179: 'OT_HOURS_FROM_ASSET',
    field_1256: 'DT_HOURS_FROM_ASSET',
    field_1180: 'IS_ORPHAN',
    field_1183: 'WAS_MODIFIED',
    field_1184: 'IS_DEFAULT',
    field_1186: 'DAILY_REPORT',
    field_1187: 'REPORT_STATUS',
    field_1190: 'TOTAL_HOURS',
    field_1191: 'LABORERS_NAME',
    field_1192: 'SHIFT_DAY_OF_WEEK',
    field_1271: 'SHIFT_DATE',
    field_1311: 'IS_ON_PAYROLL',
    field_1312: 'PAYROLL_DATE',
    field_1347: 'TOTAL_HOURS_FROM_ASSET',
    field_1382: 'EQUIPMENT_TYPE',
    field_1383: 'REPORT_DATE',
    field_1384: 'COST_CLASS',
    field_1385: 'DAILY_RATE',
    field_1395: 'JOB_NUMBER',
    field_1428: 'EQUIPMENT_USAGE_REPORT_DATE',
  },
  CHANGE_ORDER_LOGS: {
    id: 'ID',
    field_1284: 'JOB',
    field_1274: 'CHANGE_ORDER_NUMBER',
    field_1275: 'DESCRIPTION',
    field_1276: 'ITEM_NUMBER',
    field_1277: 'WORK_START_DATE',
    field_1422: 'DROPBOX_FILE_LINK',
    field_1278: 'WORK_COMPLETION_DATE',
    field_1279: 'TOTAL_AMOUNT',
    field_1280: 'DATE_SUBMITTED',
    field_1281: 'NYSDOT_STATUS',
    field_1282: 'PAYMENT_STATUS',
    field_1283: 'NOTES',
  },
  SUBMITTAL_LOGS: {
    id: 'ID',
    field_1286: 'JOB',
    field_1285: 'SUBMITTAL_NUMBER',
    field_1287: 'ITEM_NUMBER',
    field_1288: 'DESCRIPTION',
    field_1315: 'SUBMITTAL_FILE',
    field_1423: 'EXTERNAL_FILE_LINK',
    field_1289: 'RESPONSIBLE_PARTY',
    field_1290: 'STATUS',
    field_1291: 'DATE_SUBMITTED',
    field_1292: 'DATE_RETURNED',
    field_1293: 'DAYS_ELAPSED',
    field_1294: 'NOTES',
    field_1424: 'COMPLETED_BY',
    field_1425: 'COMMENTS',
  },
  JOB_COST_CODES: {
    id: 'ID',
    field_1371: 'FINGERPRINT',
    field_1295: 'COST_CODE',
    field_1297: 'JOB',
    field_1300: 'UNIT_OF_MEASUREMENT',
    field_1307: 'DESCRIPTION',
    field_1299: 'LOCAL_DESCRIPTION',
    field_1308: 'LOCAL_UNIT_OF_MEASUREMENT',
    field_1340: 'UNIT_PRICE',
    field_1356: 'CONTRACT_QUANTITY',
    field_1359: 'HIDE_FROM_WORK_ITEM_PICKLIST',
    field_1378: 'CONDITIONAL_DESCRIPTION',
    field_1379: 'PICKLIST_TITLE',
    field_1381: 'CONDITIONAL_UNIT_TYPE',
    field_1409: 'TOTAL_BILLABLE_VALUE',
    field_1443: 'FOUNDATION_ID',
    field_1444: 'FINGERPRINT_COPY',
    field_1445: 'FOUNDATION_ID_COPY',
    field_1446: 'QUANTITY',
    field_1447: 'UNIT',
    field_1448: 'UNITPRICE',
    field_1449: 'SHORTDESC',
    field_1450: '',
    field_1452: 'FOUNDATION_ID_COPY_COPY',
    field_1453: 'LOCAL_UNIT_OF_MEASURE',
  },
  JOB_PHASES: {
    id: 'ID',
    field_1302: 'PHASE',
    field_1303: 'JOB',
    field_1304: 'LOCAL_DESCRIPTION',
    field_1305: 'DESCRIPTION',
  },
  SUBCONTRACTOR_INSURANCE_LOGS: {
    id: 'ID',
    field_1326: 'CONTRACT_NUMBER',
    field_1325: 'JOB',
    field_1316: 'SUBCONTRACTOR',
    field_1317: 'INSURANCE_TYPE',
    field_1320: 'EFFECTIVE_DATE',
    field_1321: 'EXPIRATION_DATE',
    field_1329: 'DAYS_REMAINING',
    field_1328: 'STATUS',
    field_1323: 'DOCUMENTS',
    field_1324: 'NOTES',
  },
  RFI_LOGS: {
    id: 'ID',
    field_1330: 'CONTRACT_NUMBER',
    field_1331: 'JOB',
    field_1332: 'RFI_NUMBER',
    field_1333: 'DESCRIPTION',
    field_1334: 'RFI_UPLOAD',
    field_1335: 'SUBMISSION_DATE',
    field_1336: 'RESPONSE_DATE',
    field_1337: 'DAYS_ELAPSED',
    field_1338: 'STATUS',
    field_1339: 'NOTES',
  },
  REQUISITION_ITEMS: {
    id: 'ID',
    field_1348: 'JOB_COST_CODE',
    field_1357: 'DAILY_REPORT',
    field_1358: 'LOCATION',
    field_1349: 'QUANTITY_COMPLETED',
    field_1350: 'APPROVED_QUANTITY',
    field_1351: 'PAID_QUANTITY',
    field_1352: 'STATUS',
    field_1353: 'REQUISITION_NUMBER',
    field_1354: 'COMPLETED_BY',
    field_1355: 'COMPLETION_NOTES',
    field_1360: 'DELTA_QUANTITY',
    field_1361: 'OVERRUN',
    field_1363: 'WCI_VALUE',
    field_1364: 'APPROVED_VALUE',
    field_1365: 'TOTAL_PAID',
    field_1366: 'DISPUTED_VALUE',
    field_1367: 'TOTAL_HELD',
    field_1380: 'TABLE_ORDER_NUMBER',
    field_1386: 'FOUNDATION_ID',
    field_1405: 'CODE_WITH_DESCRIPTION',
    field_1415: 'RECONCILIATION_NOTES',
  },
  TASK_UPDATES: {
    id: 'ID',
    field_1397: 'NOTE',
    field_1398: 'TASK',
    field_1399: 'CREATED_AT',
  },
}
