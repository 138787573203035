<template>
  <VContainer fluid class="meta--wrap" py-1>
    <VLayout justify-space-between>
      <template v-for="field of fields">
        <div :key="field.title" class="report-meta flex text-xs-left">
          <div class="meta-title">{{ field.title }}</div>
          <div v-if="field.title === 'Job Number' && isProjMgr">
            <JobAssetManagerModal
              v-if="job"
              :job="job"
              :show="showJobAssetManager"
              @close="showJobAssetManager = false"
            />

            <VMenu open-on-hover nudge-bottom="20" nudge-left="0">
              <template v-slot:activator="{ on }">
                <BaseButton
                  color="white"
                  flat
                  class="pa-0 ma-0 manage-job-assets-btn"
                  v-on="on"
                  >{{ field.value || '-' }}</BaseButton
                >
              </template>
              <VList subheader dense>
                <VListTile @click="showJobAssetManager = true">
                  <VIcon class="mr-1">change_circle</VIcon>
                  <VListTileContent>
                    <VListTileTitle>Manage Job Assets</VListTileTitle>
                  </VListTileContent>
                </VListTile>
              </VList>
            </VMenu>
          </div>

          <div v-else class="value">{{ field.value || '-' }}</div>
        </div>
      </template>
    </VLayout>
  </VContainer>
</template>

<script>
import JobAssetManagerModal from '@modals/JobAssetManagerModal'
import { mapGetters } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getJobAssetsField',
  mutationType: 'updateJobAssetsField',
})

export default {
  name: 'LocationMeta',
  components: {
    JobAssetManagerModal,
  },
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    job: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapFields(['showJobAssetManager']),
    ...mapGetters(['isProjMgr']),
  },
}
</script>

<style lang="scss" scoped>
.meta--wrap {
  background-color: $gray-dark;
}

.report-meta {
  padding: 5px;
}

.manage-job-assets-btn {
  height: auto;
}

.meta-title {
  margin-bottom: 0.3em;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.1;
  text-align: left;
}
</style>
