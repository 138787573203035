export const humanReadableFields = {
  JOBS: {
    ID: 'id',
    JOB_NAME: 'field_1',
    JOB_NUMBER: 'field_16',
    JOB_STATUS: 'field_2',
    COMPANY: 'field_32',
    NUMBER_OF_LOCATIONS: 'field_33',
    NUMBER_OF_COMPLETED_LOCATIONS: 'field_34',
    NUMBER_OF_SCHEDULED_LOCATIONS: 'field_35',
    NUMBER_OF_LOCATIONS_READY_TO_WORK: 'field_36',
    NUMBER_OF_LOCATIONS_ON_HOLD: 'field_37',
    TOTAL_INVOICED_AMOUNT: 'field_38',
    TOTAL_LOCATION_LF: 'field_41',
    TOTAL_COMPLETED_LF: 'field_42',
    ESTIMATED_COSTS: 'field_76',
    ESTIMATED_BILLINGS: 'field_77',
    JOB_ID: 'field_80',
    PROJECT_MANAGER: 'field_120',
    DATE_BID_SUBMITTED: 'field_132',
    DATE_JOB_AWARDED: 'field_133',
    PROJECTED_START_OF_WORK: 'field_134',
    PROJECTED_COMPLETION_OF_WORK: 'field_135',
    JOB_SETUP_DUE_DATE: 'field_137',
    PROFIT_MARGIN: 'field_143',
    LOCATIONS_WAITING_FOR_NTP: 'field_147',
    NUMBER_OF_PERMITS_AWAITING_APPROVAL: 'field_148',
    NUMBER_OF_PERMITS_AWAITING_PAYMENT: 'field_149',
    NUMBER_OF_LOCATIONS_INVOICED: 'field_150',
    NUMBER_OF_LOCATIONS_PAID: 'field_151',
    NUMBER_OF_PERMITS_APPLIED_FOR_INCLUSIVE_OF_SUBSEQUENT_STATUSES: 'field_153',
    NUMBER_OF_PERMITS_PAID_FOR_INCLUSIVE_OF_SUBSEQUENT_STATUSES: 'field_154',
    NUMBER_OF_LOCATIONS_COMPLETED_INCLUSIVE_OF_SUBSEQUENT_STATUSES: 'field_155',
    NUMBER_OF_LOCATIONS_BILLED_INCLUSIVE_OF_SUBSEQUENT_STATUSES: 'field_156',
    CLIENT_NAME: 'field_296',
    NUMBER_OF_LOCATIONS_AWAITING_NTP: 'field_489',
    MOST_RECENT_DAILY_REPORT: 'field_737',
    NUMBER_OF_MATERIAL_PURCHASE_ORDERS: 'field_883',
    TOTAL_INVENTORY_PURCHASES: 'field_888',
    TOTAL_VALUE_REMAINING_INVENTORY: 'field_889',
    TOTAL_VALUE_INVENTORY_USED: 'field_890',
    TOTAL_ALL_MATERIAL_COSTS: 'field_894',
    SCHEDULED_BID_DATE: 'field_936',
    PREBID_MEETING_DATE: 'field_937',
    ESTIMATOR_NOTES_AND_COMMENTS: 'field_938',
    MP_DECISION: 'field_939',
    ESTIMATED_BID_VALUE: 'field_940',
    BID_ITEM_SHEET: 'field_941',
    PLANS_AND_SPECS: 'field_942',
    SHORT_SUMMARY_OF_WORK: 'field_943',
    BID_DOCUMENTS__UNIT_PRICES: 'field_944',
    HCSS_BID_: 'field_945',
    LOW_BIDDER: 'field_958',
    BID_PLACE_XX_OUT_OF_YY: 'field_959',
    LOW_BID_: 'field_960',
    WCI_BID_PRICE: 'field_961',
    TOTAL__OF_BIDDERS: 'field_962',
    WCI_PLACE: 'field_963',
    BID_RESULTS: 'field_964',
    CIVIL_BID_DOCUMENTS: 'field_986',
    COST_CODES: 'field_1112',
    PHASES: 'field_1113',
    SUM_TOTAL__REPORTED: 'field_1265',
    TOTAL_APPROVED_VALUE: 'field_1266',
    SUM_TOTAL_PAID: 'field_1267',
    TOTAL_DISPUTED_: 'field_1268',
    TOTAL_HELD_: 'field_1269',
    CONTRACT_NUMBER: 'field_1341',
    ALLOW_REQUISITION_ITEMS: 'field_1368',
    REQUIRES_5G_FOUNDATION: 'field_1401',
    TOTAL_BILLABLE_VALUE: 'field_1410',
  },
  LOCATIONS: {
    ID: 'id',
    IDENTIFIER: 'field_19',
    JOB: 'field_4',
    LOCATION: 'field_5',
    DEFAULT_PHASE: 'field_1010',
    PHASE_: 'field_332',
    PERMIT__WORK_HOURS: 'field_7',
    LF: 'field_21',
    CODE_53_MARKOUT_DATE: 'field_22',
    STATUS: 'field_23',
    COMPLETION_DATE: 'field_24',
    INVOICE_AMOUNT: 'field_27',
    INVOICE_DATE: 'field_28',
    PAID_DATE: 'field_29',
    STATUS_COMMENTS: 'field_30',
    PERMITTED_START_DATE: 'field_46',
    PERMITTED_END_DATE: 'field_47',
    PROTECTED: 'field_49',
    BUILDING_ACCESS: 'field_50',
    PAID_AMOUNT: 'field_73',
    PERMITS: 'field_75',
    LOCATION_ID: 'field_78',
    NOTICE_TO_PROCEED: 'field_85',
    DATE_PERMIT_APPLIED_FOR: 'field_92',
    DATE_PERMIT_PAID_FOR: 'field_93',
    WALK_THRU_DATE: 'field_94',
    INVOICE_: 'field_95',
    CHANGE_ORDER_1_INVOICE_: 'field_96',
    CHANGE_ORDER_2_INVOICE_: 'field_97',
    CHANGE_ORDER_3_INVOICE_: 'field_98',
    CHANGE_ORDER_1_INVOICE_AMOUNT: 'field_99',
    CHANGE_ORDER_2_INVOICE_AMOUNT: 'field_100',
    CHANGE_ORDER_3_INVOICE_AMOUNT: 'field_101',
    TOTAL_BILLING: 'field_102',
    PERMIT_APPLICATION_: 'field_104',
    PERMIT_APPLICATION_NUMBER_LENGTH: 'field_1454',
    LOCATION_TYPE: 'field_108',
    SPECIAL_STIPULATIONS: 'field_109',
    PROJECT_MANAGER: 'field_122',
    PERMIT_START_TIME: 'field_130',
    PERMIT_END_TIME: 'field_131',
    CREATED_DATE: 'field_138',
    SCHEDULED_WORK_DATE: 'field_141',
    CODE_53_TICKET_: 'field_157',
    DATE_PERMIT_APPROVED: 'field_160',
    CO_1_INVOICE_DATE: 'field_192',
    CO_2_INVOICE_DATE: 'field_193',
    CO_3_INVOICE_DATE: 'field_194',
    PO_: 'field_239',
    PO_REQUEST_DATE: 'field_240',
    PO_FILE: 'field_241',
    PO_AMOUNT: 'field_242',
    JOB_NUMBER: 'field_295',
    CLIENT_NAME: 'field_297',
    JOB_NUMBER__IDENTIFIER: 'field_337',
    JOB_TYPE: 'field_361',
    DEFAULT_COST_CODE: 'field_364',
    DEFAULT_COST_CODE_NUMBER: 'field_1175',
    CLIENT: 'field_463',
    PERMIT_: 'field_465',
    PROJECT_MANAGER_NOTES: 'field_475',
    TRAFFIC_STIPULATIONS_AND_CLOSURES: 'field_476',
    DATE_SURVEYED: 'field_477',
    SURVEY_NOTES: 'field_478',
    SURVEY_DOCS: 'field_479',
    SURVEYED_BY: 'field_480',
    REQUEST_SURVEY: 'field_481',
    SURVEY_PRIORITY: 'field_671',
    LOCATION_PLAN_DRAWING: 'field_490',
    OWNER_REPRESENTATIVE: 'field_492',
    FINAL_RESTORATION: 'field_494',
    MICRO_ID: 'field_495',
    ARE_ALL_DAILIES_COMPLETED_AND_CORRECT: 'field_521',
    HAS_FINAL_RESTORATION_BEEN_ORDERED: 'field_522',
    HAS_INVOICE_PACKAGE_BEEN_SUBMITTED: 'field_523',
    HAS_PROTECTED_STREET_BEEN_LOGGED_IN: 'field_524',
    EMERGENCY_LOCATION: 'field_602',
    NUMBER_OF_DAILY_REPORTS: 'field_603',
    NTP_DATE: 'field_613',
    PO_DATE: 'field_614',
    ON_HOLD: 'field_616',
    RFP_DATE: 'field_644',
    BID_BY: 'field_645',
    BID_DATE: 'field_646',
    BID_PACKAGE: 'field_647',
    RFP_EMAIL: 'field_648',
    DATE_SURVEY_REQUESTED: 'field_649',
    BARRELS_COMPLETED: 'field_659',
    VZ_SCHEDULED: 'field_662',
    SUBMITTED_PRICE_PROPOSAL: 'field_660',
    BID_IN_REVIEW: 'field_661',
    BID_AWARDED: 'field_669',
    AWARD_DATE: 'field_670',
    BID_STATUS: 'field_736',
    EARLIEST_DAILY_REPORT: 'field_673',
    MOST_RECENT_REPORT_DATE: 'field_674',
    JOB_CLEANUP_WORK_ORDER_SCHEDULED: 'field_678',
    DATE_THAT_MOST_RECENT_SETUP_WAS_CREATED: 'field_687',
    MOST_RECENT_SETUP_DATE_FORMULA: 'field_689',
    MOST_RECENT_REPORT_DATE_FORMULA: 'field_690',
    MOST_RECENT_REPORT_CREATED_DATE: 'field_699',
    MOST_RECENT_REPORT_CREATED_DATE_FORMULA: 'field_700',
    LOCATION_NEEDS_SETUP: 'field_688',
    VZ_INSPECTION_REQUIRED: 'field_739',
    COST_ESTIMATE: 'field_754',
    BID_NOTES: 'field_820',
    ASBUILT_DRAWING: 'field_872',
    INVOICE_PACKAGE: 'field_873',
    CHANGE_ORDER_INVOICE_PACKAGE: 'field_874',
    AFFILIATED_LOCATION: 'field_911',
    CONSTRUCTION_PHOTO_WALLET: 'field_947',
    PRECONSTRUCTION_VIDEO: 'field_948',
    POSTCONSTRUCTION_VIDEO: 'field_949',
    GOOGLE_DRIVE: 'field_957',
    GOOGLE_DRIVE_APP_MOBILE_LINK: 'field_980',
    PAVING_REQUIRED: 'field_967',
    CLEANUP_REQUESTED: 'field_973',
    DESCRIPTION_OF_CLEANUP_NEEDED: 'field_984',
    JOB_CONTRACT_NUMBER: 'field_1342',
    JOB_ALLOWS_REQUISITION_ITEMS: 'field_1369',
    JOB_REQUIRES_5G_FOUNDATION: 'field_1402',
    PERMIT_STATUS: 'field_1414',
    VZ_INSPECTION_DATE: 'field_1430',
    INSTALL_5G_FOUNDATION_DATE: 'field_1433',
    LOCATION_PLAN_DRAWING_LARAVEL: 'field_1442',
  },
  CREW: {
    ID: 'id',
    CREW_NAME: 'field_10',
    NUMBER_OF_DEPLOYMENTS_SCHEDULED_AFTER_TODAY: 'field_74',
    CREW_ID: 'field_82',
    CREW_STATUS: 'field_145',
    CREW_NOTES: 'field_146',
    FOREMAN: 'field_611',
  },
  TASKS: {
    ID: 'id',
    TASK_NAME: 'field_13',
    JOB: 'field_136',
    ASSIGNED_TO: 'field_124',
    TASK_DESCRIPTION: 'field_127',
    TASK_DUE_DATE: 'field_128',
    TASK_STATUS: 'field_129',
    LINK: 'field_1254',
    TASK_ID: 'field_83',
    PROJECT: 'field_1404',
  },
  COMPANY: {
    ID: 'id',
    COMPANY_NAME__AGENCY: 'field_17',
    COMPANY_ID: 'field_79',
    ACCOUNTING_DEPT_POINT_OF_CONTACT: 'field_105',
    ACCOUNTING_DEPT_EMAIL: 'field_106',
    ACCOUNTING_DEPT_PHONE_NUMBER: 'field_107',
    ESTIMATORS: 'field_977',
    SNOWFLAKE: 'field_1434',
  },
  ACCOUNTS: {
    ID: 'id',
    NAME: 'field_53',
    EMAIL: 'field_54',
    PASSWORD: 'field_55',
    USER_STATUS: 'field_56',
    USER_ROLES: 'field_57',
  },
  ESTIMATOR: {
    ID: 'id',
    NAME: 'field_58',
    EMAIL: 'field_59',
    PASSWORD: 'field_60',
    USER_STATUS: 'field_61',
    USER_ROLES: 'field_62',
  },
  NODE_SCHEDULER: {
    ID: 'id',
    NAME: 'field_63',
    EMAIL: 'field_64',
    PASSWORD: 'field_65',
    USER_STATUS: 'field_66',
    USER_ROLES: 'field_67',
  },
  MANAGERADMIN: {
    ID: 'id',
    NAME: 'field_68',
    EMAIL: 'field_69',
    PASSWORD: 'field_70',
    USER_STATUS: 'field_71',
    USER_ROLES: 'field_72',
  },
  PERMIT_MANAGEMENT: {
    ID: 'id',
    NAME: 'field_110',
    EMAIL: 'field_111',
    PASSWORD: 'field_112',
    USER_STATUS: 'field_113',
    USER_ROLES: 'field_114',
  },
  PROJECT_MANAGER: {
    ID: 'id',
    NAME: 'field_115',
    EMAIL: 'field_116',
    PASSWORD: 'field_117',
    USER_STATUS: 'field_118',
    USER_ROLES: 'field_119',
  },
  SUB_CONTRACTOR: {
    ID: 'id',
    NAME: 'field_163',
    EMAIL: 'field_164',
    PASSWORD: 'field_165',
    USER_STATUS: 'field_166',
    USER_ROLES: 'field_167',
    SUB_CONTRACTOR_COMPANY_NAME: 'field_181',
  },
  DAILY_REPORT: {
    ID: 'id',
    WEATHER: 'field_245',
    DAILY_REPORT_NAME: 'field_243',
    REPORT_DATE: 'field_244',
    SUBCONTRACTORSSUPPLIERS: 'field_246',
    MATERIAL_ORDERED: 'field_247',
    TODAYS_SCOPE_OF_WORK: 'field_248',
    FOREMANS_NOTES: 'field_249',
    PAYROLL_NOTES: 'field_1093',
    DESCRIPTION_OF_WORK_COMPLETED_TODAY: 'field_250',
    DESCRIPTION_OF_ANY_ACCIDENTSINCIDENTS_TODAY: 'field_251',
    LOCATION: 'field_252',
    LOCATION_IDENTIFIER: 'field_253',
    LOCATION_ADDRESS: 'field_254',
    PERMIT_INFO: 'field_255',
    CLIENT_NAME: 'field_298',
    REPORT_STATUS: 'field_299',
    JOB_NUMBER: 'field_305',
    PERMIT_START_TIME: 'field_307',
    PERMIT_END_TIME: 'field_308',
    LOCATION_PHASE_: 'field_333',
    FOREMAN: 'field_334',
    FOREMANS_NAME: 'field_335',
    FOREMANS_EMAIL: 'field_336',
    DAILY_REPORT_NAME_FORMULA: 'field_353',
    JOB_TYPE: 'field_356',
    COST_CODE: 'field_366',
    CHANGE_OF_CONDITIONS: 'field_369',
    APP_LINK: 'field_373',
    APP_LINK_URL: 'field_374',
    CHANGE_OF_CONDITIONS_NOTES: 'field_393',
    INCIDENT_ON_SITE: 'field_394',
    INCIDENT_REPORT_COMPLETED: 'field_395',
    PERMIT_INFO_FOR_REPORT_APP: 'field_483',
    REVISIONS: 'field_484',
    NUMBER_OF_MATERIAL_USAGES: 'field_606',
    NUMBER_OF_COMPLETED_WORK_ITEMS: 'field_607',
    LOCATION_PERMIT_WORK_HOURS: 'field_618',
    PROJECT_MANAGER_NOTES_FROM_LOCATION: 'field_642',
    WORK_ORDERS: 'field_668',
    REPORT_DATE_FORMULA: 'field_672',
    CREATED_DATE: 'field_697',
    REPORT_CREATED_DATE_FORMULA: 'field_698',
    GOOGLE_DRIVE_FROM_LOCATION: 'field_974',
    JOB_PHOTOS_UPLOADED: 'field_978',
    ASBUILT_UPLOADED: 'field_979',
    JOB_PHOTOS_EMAIL_SENT: 'field_981',
    ASBUILT_EMAIL_SENT: 'field_982',
    JOB_CONTRACT_NUMBER: 'field_1343',
    JOB_ALLOWS_REQUISITION_ITEMS: 'field_1396',
    RENTAL_DUMP_TRUCK_NOTES: 'field_1413',
    NUMBER_OF_LABOR_ASSIGNMENTS: 'field_1431',
    NUMBER_OF_EQUIPMENT_USAGES: 'field_1432',
  },
  LABORER: {
    ID: 'id',
    LABORER_NAME: 'field_256',
    EMPLOYEE_NUMBER: 'field_257',
    LABORER_TYPE: 'field_258',
    STATUS: 'field_300',
    EMAIL: 'field_304',
    ON_PAYROLL: 'field_342',
    HOURLY_LABOR_RATE_REG: 'field_370',
    HOURLY_LABOR_RATE_OT: 'field_371',
    HOURLY_LABOR_RATE_DT: 'field_372',
    UNION: 'field_405',
    DEFAULT_CREW: 'field_612',
    LABORER_RATING: 'field_617',
    CREW_NUMBER: 'field_641',
    EXCLUDE_FROM_DISPATCHER: 'field_643',
    DEFAULT_FOREMAN: 'field_813',
    SMS_NOTIFICATION_PHONE_NUMBER: 'field_819',
    LABOR_RATE: 'field_902',
    FIRST_NAME: 'field_1015',
    LAST_NAME: 'field_1016',
    RECORD_ID: 'field_1017',
    LABORER_NAME_FORMULA: 'field_1024',
    NOTES: 'field_1026',
    EXCLUDE_FROM_SCHEDULER: 'field_1400',
    SCHEDULER_ORDER: 'field_1403',
  },
  EQUIPMENT: {
    ID: 'id',
    EQUIPMENT_NAME: 'field_259',
    ASSET_NUMBER: 'field_260',
    STATUS: 'field_301',
    INCLUDE_IN_JOB_TYPE_DEFAULT: 'field_357',
    DEFAULT_NOTES: 'field_615',
    EQUIPMENT_TYPE: 'field_752',
    DAILY_RATE: 'field_753',
    OUT_OF_SERVICE: 'field_836',
    EQUIPMENT_TYPE_NAME: 'field_909',
    DEFAULT_FOREMAN: 'field_976',
    STANDBY_RATE: 'field_1028',
    EXCLUDE_FROM_BILLING: 'field_1412',
  },
  MATERIALPART: {
    ID: 'id',
    MATERIALPART_NAME: 'field_261',
    HAS_INVENTORY: 'field_1419',
    PART_ID: 'field_262',
    UNIT_TYPE: 'field_263',
    STATUS: 'field_302',
    INCLUDE_IN_JOB_TYPE_DEFAULT: 'field_358',
    INCLUDE_IN_JOB_TYPE_PICKLIST: 'field_469',
    UNIT_COST: 'field_663',
  },
  PAYWORK_ITEM: {
    ID: 'id',
    PAYWORK_ITEM_NAME: 'field_264',
    UNIT_TYPE: 'field_289',
    UNIT_PRICE: 'field_291',
    STATUS: 'field_303',
    MATCHING_COST_CODE: 'field_1437',
    ITEM_: 'field_323',
    INCLUDE_IN_JOB_TYPE_DEFAULT: 'field_359',
    RECORD_ID: 'field_402',
    INCLUDE_IN_JOB_TYPE_PICKLIST: 'field_470',
    DESCRIPTION: 'field_863',
    PAYMENT_REQUISITION: 'field_965',
    _BID_UNIT_PRICE_: 'field_989',
    MATCHING_COST_CODE_NUMBER_FORMULA: 'field_1438',
  },
  LABOR_ASSIGNMENT: {
    ID: 'id',
    LABOR_ASSIGNMENT_NAME: 'field_265',
    LABORER: 'field_266',
    START_TIME: 'field_267',
    END_TIME: 'field_268',
    LOCATION_START_: 'field_269',
    NO_LUNCH: 'field_271',
    TOTAL_HOURS: 'field_272',
    DAILY_REPORT: 'field_275',
    LABORERTYPE: 'field_306',
    LABORER_STATUS: 'field_309',
    LABORER_EMPLOYEE_NUMBER: 'field_310',
    LABORER_NAME: 'field_311',
    TABLE_ORDER_NUMBER: 'field_345',
    LABOR_ASSIGNMENT_ID: 'field_349',
    REPORT_DATE: 'field_387',
    PAYROLL_DATE: 'field_360',
    LOCATION: 'field_378',
    JOB_NUMBER: 'field_362',
    DEFAULT_PHASE: 'field_363',
    ON_PAYROLL: 'field_365',
    COST_CODE: 'field_367',
    REPORT_STATUS: 'field_368',
    DAY_OF_WEEK: 'field_386',
    REGULAR_HOURS: 'field_273',
    UNION: 'field_407',
    UNION_TEXT: 'field_408',
    OT: 'field_274',
    TIME__12: 'field_403',
    DOUBLE_TIME: 'field_404',
    DATE: 'field_409',
    NUMBER_OF_HOURS_FOR_TIME__MATERIALS_BILLING: 'field_471',
    ASSIGNMENT_SMS_STATUS: 'field_842',
    LABORER_PHONE_NUMBER_SANITIZED: 'field_1408',
    LOCATION_ADDRESS: 'field_843',
    LOCATION_IDENTIFIER: 'field_844',
    CLASSIFICATION: 'field_880',
    LABOR_RATE: 'field_901',
    HOURLY_RATE_REGULAR: 'field_903',
    HOURLY_RATE_OT: 'field_906',
    HOURLY_RATE_DT: 'field_907',
    TOTAL_LABOR_COST: 'field_908',
    COST_CODE_1: 'field_1002',
    COST_CODE_1_: 'field_995',
    COST_CODE_2: 'field_1003',
    COST_CODE_2_: 'field_997',
    PHASE_1: 'field_1007',
    PHASE_1_: 'field_994',
    PHASE_2: 'field_1008',
    PHASE_2_: 'field_996',
    NAME_LAST_FIRST: 'field_1018',
    NOTES: 'field_1025',
    COST_CODE_NUMBER_1: 'field_1038',
    COST_CODE_NUMBER_2: 'field_1039',
    PHASE_NUMBER_1: 'field_1040',
    PHASE_NUMBER_2: 'field_1041',
    TOTAL_SHIFT_ITEMS: 'field_1272',
  },
  EQUIPMENT_USAGE: {
    ID: 'id',
    EQUIPMENT_USAGE_NAME: 'field_276',
    DAILY_REPORT: 'field_277',
    EQUIPMENT: 'field_278',
    LABORER: 'field_279',
    EQUIPMENT_NAME: 'field_312',
    EQUIPMENT_ASSET_: 'field_313',
    EQUIPMENT_STATUS: 'field_314',
    LABORER_STATUS: 'field_315',
    LABORER_NAME: 'field_316',
    LABORER_TYPE: 'field_317',
    LABORER_EMPLOYEE_NUMBER: 'field_318',
    EQUIPMENT_ON_SITE_TODAY: 'field_329',
    NULL_VALUE_RECORD: 'field_331',
    EQUIPMENT_NOTES: 'field_340',
    TABLE_ORDER_NUMBER: 'field_346',
    EQUIPMENT_USAGE_ID: 'field_350',
    LOCATION: 'field_380',
    REPORT_DATE: 'field_383',
    DAILY_RATE: 'field_392',
    PHASE_: 'field_396',
    COST_CODE: 'field_397',
    JOB_NUMBER: 'field_400',
    INCLUDE_IN_TIME__MATERIALS_BILLING: 'field_472',
    COST_CLASS: 'field_485',
    COST_UNIT: 'field_486',
    TYPE: 'field_487',
    JOB_IDENTIFIER: 'field_488',
    TM_HOURS: 'field_588',
    EQUIPMENT_TYPE: 'field_910',
    LOCATION_IDENTIFIER: 'field_988',
    STANDBY: 'field_1014',
    STANDBY_BOOLEAN: 'field_1029',
    TOTAL_SHIFT_ITEMS: 'field_1273',
  },
  MATERIAL_USAGE: {
    ID: 'id',
    MATERIAL_USAGE_NAME: 'field_280',
    DAILY_REPORT: 'field_281',
    MATERIALPART: 'field_282',
    QUANTITY_USED: 'field_283',
    QUANTITY_SENT_OUT: 'field_284',
    UNIT_TYPE: 'field_293',
    MATERIAL_NAME: 'field_319',
    MATERIAL_PART_ID: 'field_320',
    MATERIAL_STATUS: 'field_321',
    MATERIAL_UNIT_TYPE: 'field_322',
    MATERIAL_NOTES: 'field_344',
    TABLE_ORDER_NUMBER: 'field_347',
    MATERIAL_USAGE_ID: 'field_351',
    LOCATION: 'field_381',
    REPORT_DATE: 'field_384',
    PHASE_: 'field_398',
    COST_CODE: 'field_399',
    JOB_NUMBER: 'field_401',
    INCLUDE_IN_TIME__MATERIALS_BILLING: 'field_473',
    JOB: 'field_773',
    JOB_INVENTORY_ITEM: 'field_772',
    COST_OF_MATERIAL_USED: 'field_786',
    IS_SPECIAL_MATERIAL: 'field_1416',
  },
  COMPLETED_WORK_QUANTITY: {
    ID: 'id',
    COMPLETED_WORK_QUANTITY_NAME: 'field_285',
    DAILY_REPORT: 'field_286',
    PAYWORK_ITEM: 'field_287',
    QUANTITY_COMPLETED: 'field_288',
    UNIT_TYPE: 'field_292',
    PAYWORK_ITEM_NAME: 'field_324',
    MATCHED_COST_CODE_NUMBER: 'field_1439',
    JOB_COST_CODE_FINGERPRINT_FORMULA: 'field_1440',
    UNIT_PRICE: 'field_325',
    PAYWORK_ITEM_STATUS: 'field_326',
    DISPUTED_: 'field_1092',
    PAYWORK_ITEM_: 'field_327',
    COMPLETION_NOTES: 'field_341',
    TABLE_ORDER_NUMBER: 'field_348',
    COMPLETED_WORK_ID: 'field_352',
    JOB_NUMBER: 'field_375',
    PHASE: 'field_376',
    REPORT_DATE: 'field_377',
    LOCATION: 'field_382',
    JOB_PAY_ITEM: 'field_797',
    TOTAL_PAID: 'field_800',
    PAYMENT_REQUISITION: 'field_966',
    NYS_DOT_APPROVED_QUANTITY: 'field_1059',
    PAID_QUANTITY: 'field_1087',
    STATUS: 'field_1060',
    TOTAL__REPORTED: 'field_1061',
    DIFF_IN_QTY: 'field_1062',
    HELD_: 'field_1063',
    APPROVED_VALUE: 'field_1088',
    OVERRUN: 'field_1091',
    COMPLETED_BY: 'field_1314',
  },
  CORRECTIVE_ACTION_REQUESTS: {
    ID: 'id',
    CORRECTIVE_ACTION_REQUESTS_NAME: 'field_412',
    DATE_RECIEVED: 'field_413',
    CAR_: 'field_414',
    FINE_AMOUNT: 'field_415',
    DESCRIPTION_OF_ACTION: 'field_416',
    LOCATION_IDENTIFIER: 'field_417',
    CAR_STATUS: 'field_418',
    FINE_PAID: 'field_419',
    DATE_FINE_PAID: 'field_420',
    AMOUNT_OF_FINE_PAID: 'field_421',
    FINE_CHECK_: 'field_422',
    DATE__WORK_COMPLETED: 'field_423',
    WORK_COMPLETED_NOTES: 'field_424',
    DATE_WORK_SCHEDULED: 'field_425',
    CAR_NAME_FORMULA: 'field_426',
    JOB: 'field_427',
    COMPANY: 'field_428',
    SCHEDULE_NOTES: 'field_429',
    WORK_TO_BE_DONE: 'field_430',
    CAR_COPY: 'field_431',
    DATE_SURVEYED: 'field_432',
    SURVEY_NOTES: 'field_433',
    WCIS_PERMIT: 'field_434',
    PERMIT_: 'field_459',
    CAR_RESOLUTION_DOCUMENT_1: 'field_460',
    CAR_RESOLUTION_DOCUMENT_2: 'field_461',
    MOST_RECENT_CAR_INSPECTION_DATE: 'field_466',
    CAR_DEADLINE_TO_RESOLVE: 'field_467',
    TYPE_OF_VIOLATION: 'field_497',
    INTERNAL_WORK_ORDER: 'field_498',
    TOTAL_FINES: 'field_510',
    WCIS_WORK: 'field_512',
    IS_VIOLATIONREPORT_ACCURATE: 'field_513',
    IS_ISSUE_RESOLVED: 'field_514',
    DATE_WORK_ORDER_CREATED: 'field_515',
    LOCATION: 'field_578',
    NUM_OF_INSPECTIONS: 'field_591',
    CAR_DATABASE_ID: 'field_592',
    SHORT_TEXT: 'field_593',
    TOTAL_FINES_PAID: 'field_597',
    TOTAL_OUTSTANDING_FINES: 'field_598',
    OUTSIDE_1YEAR_GUARANTEE: 'field_808',
    BILLED_TO_CLIENT: 'field_1052',
    INVOICED: 'field_1411',
  },
  SUMMONS: {
    ID: 'id',
    SUMMONS_NAME: 'field_435',
    LOCATION_IDENTIFIER_FOR_JOB: 'field_436',
    CORRECTIVE_ACTION_REQUEST: 'field_437',
    SUMMONS_: 'field_438',
    DATETIME_OF_SUMMONS: 'field_439',
    HEARING_DATETIME: 'field_440',
    HEARING_LOCATION: 'field_441',
    DETAILS_OF_VIOLATION: 'field_442',
    PERMIT_: 'field_443',
    MAX_PENALTY: 'field_444',
    CAR_: 'field_445',
    CLIENT_NAME: 'field_446',
    AMOUNT_OF_FINE_PAID: 'field_447',
    CHECK__FOR_FINE: 'field_448',
    DATE_FINE_PAID: 'field_449',
    WCI_RESPONSE_DATE: 'field_450',
    WCI_RESPONSE_DOCUMENT: 'field_451',
    COURT_APPEARANCE: 'field_452',
    SUMMARY_OF_APPEARANCE: 'field_453',
    DECISION_BY_COURT: 'field_454',
    DATE_OF_DECISION: 'field_455',
    STATUS: 'field_456',
    WCI_RESPONSE_NOTES: 'field_457',
    MIN_PENALTY: 'field_458',
    CLIENT: 'field_462',
    SUMMONS_COPY: 'field_464',
    FINE_RECEIPTDOCS: 'field_468',
    STREET_AND_LOCATION_OF_SUMMONS: 'field_482',
    TYPE_OF_VIOLATION: 'field_499',
    BACK_CHARGE_SUB: 'field_500',
    JOB_NUMBER: 'field_542',
    WCIS_PERMIT: 'field_543',
    OK_TO_PAY: 'field_544',
    COURT_RULING_DOC: 'field_587',
    JOB_: 'field_821',
    _OF_MISSED_HEARINGS: 'field_822',
    OUTSIDE_OF_1YEAR_WARRANTY: 'field_845',
    _MAX_PENALTY_: 'field_846',
    _SUMMARY_OF_APPEARANCE_: 'field_847',
    AMOUNT_PAID: 'field_848',
    BILLED_TO_CLIENT: 'field_1053',
  },
  CAR_INSPECTIONS: {
    ID: 'id',
    CAR_INSPECTIONS_NAME: 'field_502',
    CORRECTIVE_ACTION_REQUEST: 'field_503',
    INSPECTION_NUMBER: 'field_504',
    INSPECTION__DATE: 'field_505',
    INSPECTION_REPORT: 'field_506',
    FINE_AMOUNT: 'field_507',
    DATE_FINE_PAID: 'field_508',
    FINE_CHECK_: 'field_509',
    CAR_DATABASE_ID: 'field_594',
    AMOUNT_OF_FINE_PAID: 'field_595',
    INSPECTION_DATABASE_ID: 'field_596',
    WCIS_WORK: 'field_599',
    WCIS_PERMIT: 'field_600',
    CAR_: 'field_601',
    COMPANY: 'field_651',
    CLIENT: 'field_653',
    JOB: 'field_654',
    LOCATION: 'field_655',
  },
  WORK_ORDERS: {
    ID: 'id',
    WORK_ORDERS_NAME: 'field_562',
    DATE_WORK_ORDER_CREATED: 'field_563',
    DATE_WORK_ORDER_COMPLETED: 'field_564',
    PICTURE_1: 'field_565',
    PICTURE_2: 'field_566',
    PICTURE_3: 'field_567',
    PICTURE_4: 'field_568',
    PICTURE_5: 'field_569',
    ASSIGNED_TO: 'field_680',
    WORK_TO_BE_DONE: 'field_571',
    CORRECTIVE_ACTION_REQUEST: 'field_572',
    SUMMONS: 'field_573',
    CAR_COPY: 'field_574',
    STATUS: 'field_575',
    CAR_: 'field_576',
    LOCATION_IDENTIFIER: 'field_664',
    WCIS_WORK: 'field_579',
    IS_VIOLATIONREPORT_ACCURATE: 'field_580',
    IS_WORK_COMPLETE: 'field_581',
    WORK_NOTES: 'field_582',
    ASSIGNED_BY: 'field_584',
    WORK_ORDER_INCREMENT: 'field_585',
    WORK_ORDER_: 'field_586',
    COPY_OF_VIOLATION: 'field_609',
    SUMMONS_: 'field_610',
    PRIORITY: 'field_665',
    WORK_ORDER_TYPE: 'field_667',
    SETUP_DIAGRAM: 'field_679',
    PICTURE_6: 'field_681',
    PICTURE_7: 'field_682',
    PICTURE_8: 'field_683',
    PICTURE_9: 'field_684',
    PICTURE_10: 'field_685',
    WHAT_DATE_DOES_THIS_SETUP_COVER_UNTIL: 'field_686',
    SET_UP_CREW_PRECONSTRUCTION_VIDEO: 'field_877',
    CLEANUP_CREW_POSTCONSTRUCTION_VIDEO: 'field_878',
    ASSOCIATED_CARVIOLATION_: 'field_983',
  },
  INSURANCE_INCIDENTS: {
    ID: 'id',
    INSURANCE_INCIDENTS_NAME: 'field_619',
    CLAIM_TYPE: 'field_620',
    DATE_OF_INCIDENTS: 'field_621',
    STATUS: 'field_622',
    DESCRIPTION_OF_INCIDENT: 'field_624',
    FIELD_INCIDENT_REPORT: 'field_625',
    NOTES_AND_UPDATED_INFORMATION: 'field_626',
    CLAIMANT: 'field_628',
    CONTACT_NAME: 'field_629',
    CONTACT_PHONE_NUMBER: 'field_630',
    JOB_NUMBER1: 'field_631',
    LOCATIONADDRESS: 'field_632',
    LOCATION: 'field_744',
    PHOTOS_UPLOAD_PDF_FILE_WITH_ALL_PHOTOS: 'field_633',
    INSURANCE_SUBMITAL_DATE_: 'field_634',
    MOST_RECENT_CORRESPONDENCE_NOTES: 'field_636',
    INSURANCE_REPRESENTATIVE: 'field_637',
    INSURANCE_COMPANY: 'field_638',
    INITIAL_INSURANCE_NOTIFICATION_DATE: 'field_639',
    PERMITS_FROM_SITE: 'field_692',
    AS_BUILT_FROM_JOB: 'field_693',
    INVOICE_FOR_JOB: 'field_694',
    SITE_VIDEO: 'field_696',
    MOST_RECENT_CORRESPONDENCE_DATE: 'field_709',
    CLAIM_NUMBER: 'field_743',
    COMPLETELY_RESOLVED: 'field_745',
    DATE_COMPLETELY_RESOLVED: 'field_746',
    DESCRIPTIONNOTES_OF_RESOLUTION: 'field_747',
    JOB_NUMBER: 'field_748',
    DAILY_REPORTS: 'field_912',
    GOOGLE_DRIVE_FOR_INCIDENT_INFO: 'field_987',
  },
  INSURANCE_CORRESPONDENCE: {
    ID: 'id',
    INCIDENT_CORRESPONDENCE_NAME: 'field_701',
    INSURANCE_INCIDENT: 'field_702',
    DATE_OF_CORRESPONDENCE: 'field_703',
    SUBJECT_OF_CORRESPONDENCE: 'field_706',
    DESCRIPTIONNOTES_OF_CORRESPONDENCE: 'field_705',
    COPY_OF_CORRESPONDENCE: 'field_704',
    DATE_OF_CORRESPONDENCE_FORMULA: 'field_707',
  },
  PAVING_SUB_WORK: {
    ID: 'id',
    PAVING_SUB_WORK_NAME: 'field_710',
    LOCATION: 'field_711',
    REQUESTED_WORK: 'field_712',
    ADDITIONAL_INSTRUCTIONS: 'field_713',
    UPLOAD_DRAWING: 'field_714',
    PARKING_STIPULATIONS: 'field_715',
    SPECIALTY_LINE_STRIPING: 'field_716',
    INSTALL_TAGS_DETAILS: 'field_717',
    PERMIT_EXPIRES: 'field_718',
    ESTIMATED_SF: 'field_719',
    DATE_SENT_TO_PAVING_SUB: 'field_720',
    STATUS_OF_PO: 'field_721',
    PROTECTED: 'field_722',
    SUB_EMAIL: 'field_723',
    DATE_WORK_COMPLETED: 'field_724',
    NOTES_FROM_SUB_ABOUT_COMPLETED_WORK: 'field_725',
    SUB_EMAIL_HELPER_FIELD: 'field_726',
    PO_INCREMENT: 'field_727',
    PO_: 'field_728',
    PERMIT: 'field_729',
    PICTURE_1: 'field_730',
    PICTURE_2: 'field_731',
    PICTURE_3: 'field_732',
    PICTURE_4: 'field_733',
    PICTURE_5: 'field_734',
    INVOICE_: 'field_735',
    ADDRESS: 'field_738',
    TOTAL_ESTIMATED_SF: 'field_760',
    ACTUAL_SF_PAVED: 'field_805',
    ACTUAL_VS_EST: 'field_806',
    COST_FOR_LOCATION: 'field_807',
    EST_COST: 'field_809',
    DO_NOT_PAY: 'field_1054',
  },
  EQUIPMENT_TYPE: {
    ID: 'id',
    EQUIPMENT_TYPE_NAME: 'field_750',
    DAILY_RATE: 'field_751',
    STANDBY_RATE: 'field_1027',
  },
  PAVING_AREA: {
    ID: 'id',
    PAVING_AREA_NAME: 'field_755',
    PAVING_SUB_WORK: 'field_756',
    LENGTH: 'field_757',
    WIDTH: 'field_758',
    SF: 'field_759',
  },
  JOB_INVENTORY_ITEM: {
    ID: 'id',
    JOB_INVENTORY_ITEM_NAME: 'field_761',
    JOB: 'field_762',
    MATERIALPART: 'field_763',
    TOTAL_PURCHASED: 'field_769',
    TOTAL_SPENT: 'field_770',
    AVERAGE_COST_OF_INVENTORY: 'field_771',
    QUANTITY_USED: 'field_774',
    COST_OF_QUANTITY_USED: 'field_775',
    QUANTITY_OF_REMAINING_INVENTORY: 'field_776',
    VALUE_OF_REMAINING_INVENTORY: 'field_777',
    UNIT_TYPE: 'field_790',
    MATERIAL_HAS_INVENTORY: 'field_1420',
  },
  JOB_PURCHASE_ORDER_MATERIAL_ITEM: {
    ID: 'id',
    JOB_MATERIAL_PURCHASE_ORDER_NAME: 'field_764',
    JOB_INVENTORY_ITEM: 'field_765',
    QUANTITY_PURCHASED: 'field_766',
    COST_PER_UNIT: 'field_767',
    TOTAL_COST: 'field_768',
    JOB_MATERIAL_PURCHASE_ORDER: 'field_779',
    UNIT_TYPE: 'field_791',
    JOB_MATERIAL_PURCHASE_ORDER_ITEM_INCREMENT: 'field_875',
    JOB_MATERIAL_PURCHASE_ORDER_ITEM_: 'field_876',
    PHASE: 'field_1064',
    COST_CODE: 'field_1065',
    QUOTE: 'field_1077',
    CERTIFICATION_STATUS: 'field_1085',
  },
  JOB_PURCHASE_ORDER: {
    ID: 'id',
    JOB_MATERIAL_PURCHASE_ORDER_NAME: 'field_778',
    JOB: 'field_781',
    TOTAL_COST_OF_MATERIAL: 'field_780',
    TOTAL_COST_OF_EQUIPMENT_RENTAL: 'field_927',
    TOTAL_SUB_COST: 'field_928',
    DATE_PO_ISSUED: 'field_782',
    STATUS_OF_PO: 'field_783',
    VENDOR: 'field_785',
    PO_NUMBER_INCREMENT: 'field_871',
    PO_NUMBER_INCREMENT_FROM_JOB: 'field_884',
    PO_NUMBER_INCREMENT_ADD_DIGITS: 'field_882',
    PO_NUMBER: 'field_881',
    TOTAL_PO_COST: 'field_929',
    VENDOR_NAME: 'field_930',
    JOB_NUMBER: 'field_933',
    INVOICE_: 'field_1055',
    INVOICED_AMOUNT: 'field_1075',
    NOTES: 'field_1076',
    CERTIFICATION_STATUS: 'field_1084',
    TAX_PERCENTAGE: 'field_1258',
    SUBTOTAL: 'field_1259',
    TOTAL_TAXES: 'field_1260',
    INVOICE_STATUS: 'field_1261',
    INVOICE_FILE: 'field_1262',
    EXPENSE_TYPE: 'field_1418',
    TAX_EXEMPTION_CERTIFICATION: 'field_1421',
    LEFT_TO_BE_INVOICED: 'field_1435',
  },
  VENDOR: {
    ID: 'id',
    VENDOR_NAME: 'field_784',
    VENDOR_NO: 'field_1071',
    STREET_ADDRESS: 'field_1072',
    CITYSTATEZIP: 'field_1073',
    CONTACT_NAME: 'field_1068',
    CONTACT_PHONE_: 'field_1069',
    CONTACT_EMAIL: 'field_1070',
    MWDBE_STATUS: 'field_1074',
  },
  JOB_PAY_ITEM: {
    ID: 'id',
    JOB_PAY_ITEM_NAME: 'field_792',
    JOB: 'field_793',
    PAYWORK_ITEM: 'field_794',
    CONTRACT_QUANTITY: 'field_795',
    CONTRACT_AMOUNT: 'field_796',
    CONTRACT_UNIT_PRICE: 'field_799',
    TOTAL_COMPLETED_TO_DATE: 'field_798',
    TOTAL_EARNED_TO_DATE: 'field_801',
    TOTAL_REMAINING_QUANTITY_COVERED_UNDER_CONTRACT: 'field_802',
    TOTAL_REMAINING_AMOUNT_COVERED_UNDER_CONTRACT: 'field_803',
    RECORD_ID: 'field_804',
  },
  EQUIPMENT_SERVICE_TICKET: {
    ID: 'id',
    EQUIPMENT_SERVICE_TICKET_NAME: 'field_828',
    EQUIPMENT: 'field_829',
    DATE_TICKET_OPENED: 'field_830',
    DATE_TICKET_RESOLVED: 'field_831',
    DESCRIPTION_OF_SERVICE_NEEDED: 'field_832',
    REQUESTED_BY: 'field_833',
    REPAIRED_BY: 'field_834',
    IS_THE_PIECE_OUT_OF_SERVICE_AND_CANNOT_BE_USED: 'field_835',
    STATUS: 'field_837',
    DATE_SERVICE_STARTED: 'field_838',
    SERVICE_PLAN: 'field_839',
    NOTES_ABOUT_RESOLUTION: 'field_840',
    WAS_PROBLEM_FIXED: 'field_841',
    PERSON_USING_EQUIPMENT_AT_TIME_OF_INCIDENT: 'field_851',
    PHOTO_1_OF_PROBLEM: 'field_852',
    PHOTO_2_OF_PROBLEM: 'field_853',
    PHOTO_3_OF_PROBLEM: 'field_854',
    TICKET_: 'field_855',
    TICKET_NUMBER: 'field_856',
    MILEAGEHOURS: 'field_857',
    FILE_1: 'field_858',
    FILE_2: 'field_859',
    PHOTO_1_OF_REPAIR: 'field_860',
    PHOTO_2_OF_REPAIR: 'field_861',
    PHOTO_3_OF_REPAIR: 'field_862',
  },
  LABOR_RATE: {
    ID: 'id',
    LABOR_RATE_NAME: 'field_895',
    EFFECTIVE_DATE_START: 'field_896',
    EFFECTIVE_DATE_END: 'field_897',
    TYPE_OF_LABORER: 'field_898',
    STATUS: 'field_900',
    HOURLY_RATE_REGULAR: 'field_899',
    HOURLY_RATE_OT: 'field_904',
    HOURLY_RATE_DT: 'field_905',
  },
  JOB_PURCHASE_ORDER_EQUIPMENT_RENTAL_ITEM: {
    ID: 'id',
    DESCRIPTION: 'field_913',
    JOB_PURCHASE_ORDER: 'field_915',
    TOTAL_ESTIMATED_COST: 'field_919',
    SUPPORTING_DOCS: 'field_925',
    VENDOR_NAME: 'field_931',
    JOB_NUMBER: 'field_934',
    RENTAL_LENGTH: 'field_1056',
    MULTIPLE_CHOICE: 'field_1057',
    RENTAL_RATE: 'field_1058',
    PHASE: 'field_1078',
    COST_CODE: 'field_1079',
  },
  JOB_PURCHASE_ORDER_SUB_CONTRACTOR_ITEM: {
    ID: 'id',
    DESCRIPTION: 'field_914',
    JOB_PURCHASE_ORDER: 'field_916',
    LUMP_SUM: 'field_922',
    QUANTITY: 'field_1257',
    SUPPORTING_DOCS: 'field_924',
    VENDOR_NAME: 'field_932',
    JOB_NUMBER: 'field_935',
    COST_CODE: 'field_1080',
    PHASE: 'field_1081',
    SUB_APPROVAL_STATUS: 'field_1086',
  },
  BID_DOCUMENTS: {
    ID: 'id',
    BID_DOCUMENTS_NAME: 'field_950',
    JOB: 'field_951',
    DATE_DOCUMENT_ENTERED: 'field_952',
    DESCRIPTION_OF_DOCUMENT: 'field_953',
    NOTES_AND_INFORMATION: 'field_954',
    FILE: 'field_955',
    THIRD_PARTY_SHARING: 'field_956',
    BID_DOCUMENT_GOOGLE_DRIVE: 'field_985',
  },
  SURVEY_TEAM: {
    ID: 'id',
    NAME: 'field_968',
    EMAIL: 'field_969',
    PASSWORD: 'field_970',
    USER_STATUS: 'field_971',
    USER_ROLES: 'field_972',
  },
  COST_CODES: {
    ID: 'id',
    COST_CODE_NUMBER: 'field_1032',
    SHIFT_ITEM_USAGES: 'field_1393',
    DESCRIPTION: 'field_1001',
    UNIT_OF_MEASUREMENT: 'field_1107',
    FOUNDATION_ID: 'field_1391',
    STATUS: 'field_1387',
    HAS_SPECIAL_SPEC: 'field_1388',
    SPEC_FILENAME: 'field_1389',
    SPEC_URL: 'field_1390',
    UNIT_OF_MEASURE: 'field_1451',
  },
  PHASES: {
    ID: 'id',
    PHASE_NUMBER: 'field_1033',
    DESCRIPTION: 'field_1006',
  },
  CATCH_BASINS: {
    ID: 'id',
    CATCH_BASIN_: 'field_1042',
    HIGHWAYROADWAY: 'field_1043',
    TRAVEL_DIRECTION: 'field_1044',
    DATE_WORK_COMPLETED: 'field_1045',
    DEPTH_OF_STRUCTURE: 'field_1046',
    DEBRIS_LEVEL: 'field_1047',
    NOTES: 'field_1048',
    TIME_TO_CLEAN: 'field_1049',
    BEFORE_PHOTO: 'field_1050',
    AFTER_PHOTO: 'field_1051',
  },
  SHIFT_ITEMS: {
    ID: 'id',
    REGULAR_HOURS: 'field_1095',
    OT_HOURS: 'field_1096',
    DT_HOURS: 'field_1193',
    PHASE: 'field_1097',
    COST_CODE: 'field_1098',
    COST_CODE_FOUNDATION_ID: 'field_1394',
    LABOR_ASSIGNMENT: 'field_1100',
    EQUIPMENT_USAGE: 'field_1101',
    TIME_CREATED: 'field_1116',
    REGULAR_HOURS_FROM_ASSET: 'field_1178',
    OT_HOURS_FROM_ASSET: 'field_1179',
    DT_HOURS_FROM_ASSET: 'field_1256',
    IS_ORPHAN: 'field_1180',
    WAS_MODIFIED: 'field_1183',
    IS_DEFAULT: 'field_1184',
    DAILY_REPORT: 'field_1186',
    REPORT_STATUS: 'field_1187',
    TOTAL_HOURS: 'field_1190',
    LABORERS_NAME: 'field_1191',
    SHIFT_DAY_OF_WEEK: 'field_1192',
    SHIFT_DATE: 'field_1271',
    IS_ON_PAYROLL: 'field_1311',
    PAYROLL_DATE: 'field_1312',
    TOTAL_HOURS_FROM_ASSET: 'field_1347',
    EQUIPMENT_TYPE: 'field_1382',
    REPORT_DATE: 'field_1383',
    COST_CLASS: 'field_1384',
    DAILY_RATE: 'field_1385',
    JOB_NUMBER: 'field_1395',
    EQUIPMENT_USAGE_REPORT_DATE: 'field_1428',
  },
  CHANGE_ORDER_LOGS: {
    ID: 'id',
    JOB: 'field_1284',
    CHANGE_ORDER_NUMBER: 'field_1274',
    DESCRIPTION: 'field_1275',
    ITEM_NUMBER: 'field_1276',
    WORK_START_DATE: 'field_1277',
    DROPBOX_FILE_LINK: 'field_1422',
    WORK_COMPLETION_DATE: 'field_1278',
    TOTAL_AMOUNT: 'field_1279',
    DATE_SUBMITTED: 'field_1280',
    NYSDOT_STATUS: 'field_1281',
    PAYMENT_STATUS: 'field_1282',
    NOTES: 'field_1283',
  },
  SUBMITTAL_LOGS: {
    ID: 'id',
    JOB: 'field_1286',
    SUBMITTAL_NUMBER: 'field_1285',
    ITEM_NUMBER: 'field_1287',
    DESCRIPTION: 'field_1288',
    SUBMITTAL_FILE: 'field_1315',
    EXTERNAL_FILE_LINK: 'field_1423',
    RESPONSIBLE_PARTY: 'field_1289',
    STATUS: 'field_1290',
    DATE_SUBMITTED: 'field_1291',
    DATE_RETURNED: 'field_1292',
    DAYS_ELAPSED: 'field_1293',
    NOTES: 'field_1294',
    COMPLETED_BY: 'field_1424',
    COMMENTS: 'field_1425',
  },
  JOB_COST_CODES: {
    ID: 'id',
    FINGERPRINT: 'field_1371',
    COST_CODE: 'field_1295',
    JOB: 'field_1297',
    UNIT_OF_MEASUREMENT: 'field_1300',
    DESCRIPTION: 'field_1307',
    LOCAL_DESCRIPTION: 'field_1299',
    LOCAL_UNIT_OF_MEASUREMENT: 'field_1308',
    UNIT_PRICE: 'field_1340',
    CONTRACT_QUANTITY: 'field_1356',
    HIDE_FROM_WORK_ITEM_PICKLIST: 'field_1359',
    CONDITIONAL_DESCRIPTION: 'field_1378',
    PICKLIST_TITLE: 'field_1379',
    CONDITIONAL_UNIT_TYPE: 'field_1381',
    TOTAL_BILLABLE_VALUE: 'field_1409',
    FOUNDATION_ID: 'field_1443',
    FINGERPRINT_COPY: 'field_1444',
    FOUNDATION_ID_COPY: 'field_1445',
    QUANTITY: 'field_1446',
    UNIT: 'field_1447',
    UNITPRICE: 'field_1448',
    SHORTDESC: 'field_1449',
    '': 'field_1450',
    FOUNDATION_ID_COPY_COPY: 'field_1452',
    LOCAL_UNIT_OF_MEASURE: 'field_1453',
  },
  JOB_PHASES: {
    ID: 'id',
    PHASE: 'field_1302',
    JOB: 'field_1303',
    LOCAL_DESCRIPTION: 'field_1304',
    DESCRIPTION: 'field_1305',
  },
  SUBCONTRACTOR_INSURANCE_LOGS: {
    ID: 'id',
    CONTRACT_NUMBER: 'field_1326',
    JOB: 'field_1325',
    SUBCONTRACTOR: 'field_1316',
    INSURANCE_TYPE: 'field_1317',
    EFFECTIVE_DATE: 'field_1320',
    EXPIRATION_DATE: 'field_1321',
    DAYS_REMAINING: 'field_1329',
    STATUS: 'field_1328',
    DOCUMENTS: 'field_1323',
    NOTES: 'field_1324',
  },
  RFI_LOGS: {
    ID: 'id',
    CONTRACT_NUMBER: 'field_1330',
    JOB: 'field_1331',
    RFI_NUMBER: 'field_1332',
    DESCRIPTION: 'field_1333',
    RFI_UPLOAD: 'field_1334',
    SUBMISSION_DATE: 'field_1335',
    RESPONSE_DATE: 'field_1336',
    DAYS_ELAPSED: 'field_1337',
    STATUS: 'field_1338',
    NOTES: 'field_1339',
  },
  REQUISITION_ITEMS: {
    ID: 'id',
    JOB_COST_CODE: 'field_1348',
    DAILY_REPORT: 'field_1357',
    LOCATION: 'field_1358',
    QUANTITY_COMPLETED: 'field_1349',
    APPROVED_QUANTITY: 'field_1350',
    PAID_QUANTITY: 'field_1351',
    STATUS: 'field_1352',
    REQUISITION_NUMBER: 'field_1353',
    COMPLETED_BY: 'field_1354',
    COMPLETION_NOTES: 'field_1355',
    DELTA_QUANTITY: 'field_1360',
    OVERRUN: 'field_1361',
    WCI_VALUE: 'field_1363',
    APPROVED_VALUE: 'field_1364',
    TOTAL_PAID: 'field_1365',
    DISPUTED_VALUE: 'field_1366',
    TOTAL_HELD: 'field_1367',
    TABLE_ORDER_NUMBER: 'field_1380',
    FOUNDATION_ID: 'field_1386',
    CODE_WITH_DESCRIPTION: 'field_1405',
    RECONCILIATION_NOTES: 'field_1415',
  },
  TASK_UPDATES: {
    ID: 'id',
    NOTE: 'field_1397',
    TASK: 'field_1398',
    CREATED_AT: 'field_1399',
  },
}
